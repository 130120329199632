<template>
  <div class="container">
    <PlatformHeader title="知识库" :showPlatform="false" />
    <div class="tools">
      <div>
        <a-select
          v-model="select_type"
          :suffixIcon="suffixIcon"
          style="width: 160px; height: 40px"
          placeholder="选择标签"
          @change="search(true)"
        >
          <a-select-option value="">全部标签</a-select-option>
          <a-select-option
            :value="item.id"
            v-for="item in tagList"
            :key="item.id"
          >
            {{ item.tag_name }}
          </a-select-option>
        </a-select>
        <a-input-search
          v-model.trim="input_content"
          v-emoji
          @search="search(true)"
          @pressEnter="search(true)"
          @blur="search(true)"
          style="width: 200px; margin-left: 8px"
          class="aft-search"
          placeholder="搜索内容名称"
        />
      </div>
      <a-button class="upload-button" @click="openDrawer">上传</a-button>
    </div>

    <div class="table-container">
      <a-table
        :columns="columns"
        :data-source="tableData"
        :rowKey="(data) => data.id"
        :pagination="false"
        :loading="taleLoading"
        :scroll="{ y: 'calc(100vh - 334px)' }"
        :locale="locale"
        class="aft-table default"
      >
        <div
          slot="num"
          slot-scope="text, records, index"
          style="padding: 25px 0"
          class="font-color1"
        >
          {{ (current - 1) * pageSize + Number(index) + 1 }}
        </div>
        <div slot="tag_name" slot-scope="text, records" style="padding: 25px 0">
          <span class="type-tag" v-if="records.tag_name">{{
            records.tag_name
          }}</span>
          <span class="font-color1" v-else>-</span>
        </div>
        <div
          slot="material_url"
          slot-scope="text, records"
          style="padding: 25px 0"
        >
          <span
            class="font-color2"
            v-if="!records.material_url || records.material_url.length == 0"
            >-</span
          >
          <div
            v-else-if="records.material_url && records.material_url.length == 1"
            class="font-color2 table-file-name"
            @click="previewFile(records.material_url[0])"
          >
            <a-tooltip>
              <template slot="title">
                {{ records.material_url[0].file_name || "-" }}
              </template>
              {{ records.material_url[0].file_name || "-" }}
            </a-tooltip>
          </div>
          <div v-else>
            <a-popover placement="bottomLeft">
              <template slot="content">
                <div class="popover-box">
                  <div
                    v-for="(item, index) in records.material_url || []"
                    :key="index"
                    class="popover-box-item"
                    @click="previewFile(item)"
                  >
                    <a-tooltip>
                      <template slot="title">
                        {{ item.file_name || "-" }}
                      </template>
                      <div class="file-name">{{ item.file_name || "-" }}</div>
                    </a-tooltip>
                    <div
                      class="action-text"
                      v-if="isPreview(item.material_url)"
                    >
                      预览
                    </div>
                  </div>
                </div>
              </template>
              <div class="table-file-box">
                <span class="font-color2">{{
                  records.material_url[0].file_name || "-"
                }}</span>
                <svg-icon
                  icon-class="select-arrow"
                  class="font-color2"
                  style="font-size: 14px"
                />
              </div>
            </a-popover>
          </div>
        </div>
        <div
          slot-scope="text, records"
          slot="action"
          style="padding: 25px 0"
          class="font-color2 action-box"
        >
          <span @click="editClick(records)">编辑</span>
          <span
            v-if="records.material_url && records.material_url.length > 0"
            @click="downLoadClick(records)"
            >下载</span
          >
          <span @click="deleteClick(records)">删除</span>
        </div>
      </a-table>
    </div>

    <div class="page-div aft-pagination">
      <a-pagination
        v-model="current"
        size="small"
        :pageSize="pageSize"
        :total="total"
        @change="pageChange"
      />
    </div>
    <FormDrawer ref="FormDrawer" @getList="search" :tagList="tagList" />
    <a-modal
      v-model="deleteModal"
      :closable="false"
      :footer="null"
      dialogClass="modal-class"
    >
      <div class="modal-container">
        <a-icon type="exclamation-circle" theme="filled" class="icon" />
        <span>确认要删除这条内容吗？</span>
      </div>
      <div class="modal-footer">
        <div class="btn btn1" @click="deleteModal = false">取消</div>
        <div class="btn btn2" @click="sureDelete">确认</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import Empty from "@/components/Empty/Empty";
import FormDrawer from "./components/FormDrawer";
import api from "@/api/knowledge";
import authApi from "@/api/auth";
export default {
  components: {
    PlatformHeader,
    Empty,
    FormDrawer,
  },
  data() {
    return {
      locale: {
        emptyText: <Empty style="margin-top: 140px;" title="暂无数据" />,
      },
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      select_type: "",
      tagList: [],
      input_content: "",
      columns: [
        {
          title: "序号",
          width: "76px",
          align: "center",
          scopedSlots: { customRender: "num" },
        },
        {
          title: "内容名称",
          dataIndex: "title",
          key: "title",
          align: "left",
          customRender: function (text, record, index) {
            return (
              <a-tooltip>
                <template slot="title">{record.title || "-"}</template>
                <div class="font-color1 ellipsis">{record.title || "-"}</div>
              </a-tooltip>
            );
          },
        },
        {
          title: "标签",
          width: "84px",
          key: "tag_name",
          align: "left",
          scopedSlots: { customRender: "tag_name" },
        },
        {
          title: "内容正文",
          dataIndex: "content",
          key: "content",
          align: "left",
          customRender: function (text, record, index) {
            return (
              <a-tooltip>
                <template slot="title">{record.content || "-"}</template>
                <div class="font-color1 ellipsis">{record.content || "-"}</div>
              </a-tooltip>
            );
          },
        },
        {
          title: "附件",
          width: "150px",
          key: "material_url",
          align: "left",
          scopedSlots: { customRender: "material_url" },
        },
        {
          title: "发布时间",
          width: "160px",
          dataIndex: "publish_time",
          key: "publish_time",
          align: "left",
          customRender: function (text, record, index) {
            return <div class="font-color1">{text}</div>;
          },
        },
        {
          title: "提交人",
          width: "76px",
          dataIndex: "actor",
          key: "actor",
          align: "left",
          customRender: function (text, record, index) {
            return <div class="font-color1">{text}</div>;
          },
        },
        {
          title: "操作",
          width: "160px",
          dataIndex: "action",
          key: "action",
          align: "left",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableData: [],
      taleLoading: false,
      currentItem: {},
      current: 1,
      pageSize: 20,
      total: 0,
      deleteModal: false,
      currentDeleteItem: {},
    };
  },
  computed: {},
  created() {
    this.getKnowledgeType();
    this.search(true);
  },
  mounted() {},
  methods: {
    // 打开上传弹窗
    openDrawer() {
      if (this.tagList.length == 0) {
        this.$message.error("当前主体未配置标签!");
        return false;
      }
      this.currentItem = {};
      this.$refs.FormDrawer.showDrawer("add", this.currentItem);
    },
    // 查询列表
    search(isFirst) {
      if (isFirst) {
        this.current = 1;
      }
      let params = {
        page_num: this.current,
        page_size: this.pageSize,
        title: this.input_content,
        tag_id: this.select_type,
      };
      this.taleLoading = true;
      api.getKnowledgeList(params).then((res) => {
        console.log("getKnowledgeList", res);
        this.taleLoading = false;
        const { code, data } = res.data;
        if (code == 0) {
          this.tableData = data.list || [];
          this.total = data.pagination.total || 0;
        } else {
          this.tableData = [];
          this.total = 0;
          this.$message.error("获取列表失败");
        }
      });
    },
    pageChange() {
      console.log(this.current);
      this.search();
    },
    getKnowledgeType() {
      api.getKnowledgeType().then((res) => {
        console.log("getKnowledgeType", res);
        const { code, data } = res.data;
        if (code == 0) {
          this.tagList = data || [];
        } else {
          this.$message.error("获取标签列表失败");
        }
      });
    },
    // 预览文件
    previewFile(fileItem) {
      console.log("判断文件格式", fileItem);
      if (this.isPreview(fileItem.material_url)) {
        if (this.isPreview(fileItem.material_url, ["mp4"])) {
          console.log("mp4可以预览");
          authApi
            .getAuthUrl({
              url: fileItem.material_url,
            })
            .then((res) => {
              const { code, data } = res.data;
              if (code == 200) {
                window.open(data);
              } else {
                this.$message.warning("获取视频地址查看权限失败");
              }
            });
        } else {
          console.log("可以预览");
          window.open(fileItem.material_url);
        }
      } else {
        console.log("不可以预览");
        this.$message.warning("当前文件不可预览");
      }
    },
    isPreview(url, list) {
      //后缀获取
      let suffix = "";
      // 获取类型结果
      let result = "";
      const flieArr = url.split(".");
      suffix = flieArr[flieArr.length - 1];
      if (suffix != "") {
        suffix = suffix.toLocaleLowerCase();
        // 可预览格式
        const previewList = list || [
          "png",
          "jpg",
          "jpeg",
          "webp",
          "mp4",
          "pdf",
        ];
        // 进行匹配
        result = previewList.find((item) => suffix.indexOf(item) >= 0);
        if (result) {
          return true;
        }
      }
      return false;
    },
    // 编辑按钮点击
    editClick(item) {
      this.currentItem = item;
      this.$refs.FormDrawer.showDrawer("edit", this.currentItem);
    },
    // 下载按钮点击
    downLoadClick(item) {
      console.log("下载这些文件", item.material_url);
      item.material_url &&
        item.material_url.length &&
        item.material_url.forEach((item) => {
          this.downLoadItem(item);
        });
    },
    // 下载单个文件
    downLoadItem(item) {
      console.log("正在下载。。", item);
      let a = document.createElement("a"); // 创建a标签
      if (this.isPreview(item.material_url, ["mp4"])) {
        authApi
          .getAuthUrl({
            url: item.material_url,
          })
          .then((res) => {
            const { code, data } = res.data;
            if (code == 200) {
              fetch(data)
                .then((res) => res.blob())
                .then((blob) => {
                  a.href = URL.createObjectURL(blob); //blob地址
                  a.download = item.file_name || "-"; // 下载文件的名字
                  a.click();
                });
            } else {
              this.$message.warning("获取视频地址查看权限失败");
            }
          });
      } else {
        fetch(item.material_url)
          .then((res) => res.blob())
          .then((blob) => {
            a.href = URL.createObjectURL(blob); //blob地址
            a.download = item.file_name || "-"; // 下载文件的名字
            a.click();
          });
      }
    },
    // 删除按钮点击
    deleteClick(item) {
      this.deleteModal = true;
      this.currentDeleteItem = item;
    },
    // 确认删除
    sureDelete() {
      this.deleteModal = false;
      // 调用删除接口 - 提示 - 刷新列表
      console.log(this.currentDeleteItem);
      let body = {
        ids: [this.currentDeleteItem.id],
      };
      api.deleteKnowledgeById(body).then((res) => {
        const { code, data } = res.data;
        if (code == 0) {
          this.$message.success("删除成功");
          this.search(true);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.container {
  .tools {
    width: 100%;
    height: 40px;
    margin-bottom: 16px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    .upload-button {
      width: 86px;
      height: 40px;
      background: #ffca5c;
      border-radius: 8px 8px 8px 8px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.88);
      border: none;
    }
  }
  .table-container {
    padding: 0 20px;
    height: calc(100vh - 278px);
    overflow-y: auto;
    .action-box {
      > span {
        margin-right: 16px;
      }
    }
    .type-tag {
      min-width: 40px;
      padding: 0 8px;
      height: 24px;
      background: rgba(255, 204, 85, 0.3);
      border-radius: 4px;
      font-size: 12px;
      color: #ffcc55;
      text-align: center;
      line-height: 24px;
      display: inline-block;
    }
    .table-file-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 12px;
      > span {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
      }
    }
    .table-file-name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
    }
  }
  .page-div {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .ellipsis {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
  .font-color1 {
    color: rgba(255, 255, 255, 0.88);
  }
  .font-color2 {
    color: #ffcc55;
  }
}

.popover-box {
  width: 228px;
  max-height: 166px;
  overflow-y: auto;
  background: #2b2f3b;
  &-item {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.88);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    .file-name {
      color: rgba(255, 255, 255, 0.88);
      width: 190px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
    .action-text {
      color: #ffcc55;
    }
  }
}

.modal-container {
  display: flex;
  align-items: center;
  height: 24px;
  .icon {
    font-size: 21px;
    color: #ff9026;

    margin-right: 17px;
  }
  > span {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 24px;
  }
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
  .btn {
    height: 32px;
    line-height: 32px;
    border-radius: 20px;
    text-align: center;
    font-size: 14px;
    border: none;
    display: inline-block;
    cursor: pointer;
  }
  .btn1 {
    width: 74px;
    background: #2b2f3b;
    color: rgba(255, 255, 255, 0.65);
  }
  .btn2 {
    width: 65px;
    background: #ffcc55;
    color: rgba(0, 0, 0, 0.88);
    margin-left: 8px;
  }
}
</style>
<style lang="scss" scoped>
/deep/ .modal-class {
  width: 360px;
  height: 136px;
  background: #1a1d26;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.32),
    0px 9px 28px 0px rgba(0, 0, 0, 0.2), 0px 12px 48px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  .ant-modal-body {
    width: 100%;
    height: 100%;
    padding: 32px 32px 24px 34px !important;
  }
  .ant-modal-content {
    width: 100%;
    height: 100%;
    background: #1a1d26 !important;
  }
}
</style>