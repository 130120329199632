<template>
  <PanleWrap>
    <div class="list_header">
      <h3 class="title">重点关注门店</h3>
    </div>
  </PanleWrap>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
    // ...mapState({
    //   calendarData: (state) => state.calendarData,
    // }),
  },
  methods: {
    // ...mapActions(["getReleasedAvg", "getReleasedCalendar"]),
  
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";

</style>