class MqttRemoval {
  constructor(obj) {
    if (Set) {
      this.buffer = new Set
    } else {
      this.buffer = []
    }
    this.obj = obj
  }

  isRemoval(obj) {
    let bool = true
    if (obj.type != 2) {
      this.buffer.forEach(item => {
        if (item.data.msgId == obj.data.msgId) {
          bool = false;
        }
      });
      if (bool) {
        this.buffer = [obj, ...this.buffer];
        if (this.buffer.length > 100) {
          this.buffer.pop();
        }
      }
    }
    return bool
  }
}

export default MqttRemoval