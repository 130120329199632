<template>
  <div class="report-container">
    <div class="d-flex align-items-center justify-content-between header">
      <img class="report-top" src="@/assets/img/report-top.png" alt="" />
      <img class="report-bottom" src="@/assets/img/report-bottom.png" alt="" />
      <div class="d-flex flex-column report-header-text">
        <span class="report-title">报表中心</span>
        <span class="report-sub-title">
          支持自定义数据报表，多处业务数据统一管理，集中满足不同场景的数据需求
        </span>
        <!-- <span class="report-sub-title time">
          公测版
          <span class="line"></span>
          2023/08/25 - 2023/09/30
        </span> -->
      </div>
      <img
        class="report-icon"
        src="@/assets/img/report-icon.png"
        alt=""
        srcset=""
      />
    </div>
    <div class="operate-box">
      <a-button
        class="new-aft-btn"
        type="primary"
        size="large"
        icon="plus"
        @click="openActivityReportEditionModal()"
      >
        创建报表
      </a-button>
    </div>
    <div>
      <a-table
        :pagination="false"
        :columns="columns"
        :dataSource="reportList"
        :loading="isLoading"
        rowKey="id"
        :scroll="{ y: 'calc(100vh - 441px)' }"
        :locale="{ emptyText: '暂无数据' }"
        class="aft-table default"
      >
        <template slot="action" slot-scope="row">
          <span
            class="btn"
            @click="openLink(row.reportTemplateLink)"
            v-if="
              row.reportTemplateIsDeleted === 0 &&
              row.reportTemplateStatus === 1
            "
          >
            查看报表
          </span>
          <span class="btn" @click="openActivityReportDetailModal(row.id)">
            详情
          </span>
          <template v-if="currentUserName === row.creator">
            <span class="btn" @click="openActivityReportEditionModal(row.id)">
              编辑
            </span>
            <span class="btn" @click="deleteReport(row.id)">删除</span>
          </template>
        </template>
        <template slot="updateFrequency" slot-scope="text,item">
          <span
            >每{{ UPDATE_FREQUENCY_TYPE[item.updateFrequency] }}更新{{
              item.frequency
            }}次</span
          >
        </template>
      </a-table>
      <div
        class="d-flex justify-content-end new-aft-pagination"
        style="margin-top: 20px"
      >
        <a-pagination
          v-if="form.total > 0"
          v-model="form.page"
          size="large"
          :pageSize="form.size"
          :total="form.total"
          @change="pageChange"
        />
      </div>
    </div>

    <ActivityReportEditionModal
      ref="ActivityReportEditionModal"
      @success="getReportList"
    />
    <ActivityReportDetailModal ref="ActivityReportDetailModal" />
  </div>
</template>

<script>
import api from "@/api/activityReport.js";
import ActivityReportEditionModal from "./components/ActivityReportEditionModal";
import ActivityReportDetailModal from "./components/ActivityReportDetailModal";
import { getUserName } from "@/utils/auth";

export default {
  components: { ActivityReportEditionModal, ActivityReportDetailModal },
  data() {
    return {
      UPDATE_FREQUENCY_TYPE: {
        1: "日",
        2: "周",
        3: "月",
        4: "季",
        5: "年",
      },
      isLoading: false,
      form: {
        page: 1,
        size: 10,
        total: 0,
      },
      columns: [
        {
          dataIndex: "reportName",
          title: "报表名称",
          align: "left",
        },
        {
          dataIndex: "reportTemplateType",
          title: "报表类型",
          align: "left",
        },
        {
          dataIndex: "nickName",
          title: "创建者",
          align: "left",
          width: 150,
        },
        {
          dataIndex: "updateFrequency",
          title: "更新频率",
          scopedSlots: { customRender: "updateFrequency" },
          align: "left",
        },
        {
          dataIndex: "ctime",
          title: "创建时间",
          align: "left",
          width: 180,
        },
        {
          title: "操作",
          align: "left",
          width: 230,
          scopedSlots: { customRender: "action" },
        },
      ],
      reportList: [],

      currentUserName: getUserName(),
    };
  },
  methods: {
    async getReportList() {
      this.isLoading = true;
      try {
        const { data: res } = await api.getReportList(this.form);
        this.isLoading = false;
        if (res.code === 200) {
          this.reportList = res.data.list;
          this.form.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    /**
     * 页数改变
     * @param {number} page 修改的页数
     */
    pageChange(page) {
      this.form.page = page;
      this.getReportList();
    },
    openLink(reportTemplateLink) {
      window.open(reportTemplateLink);
    },
    openActivityReportEditionModal(id) {
      this.$refs["ActivityReportEditionModal"].openModal(id);
    },
    openActivityReportDetailModal(id) {
      this.$refs["ActivityReportDetailModal"].openModal(id);
    },
    deleteReport(id) {
      this.$confirm({
        icon: () => <a-icon type="info-circle" theme="filled" />,
        title: "删除后将无法恢复，确认要删除这个报表吗？",
        onOk: () => {
          return api
            .deleteReport({ id })
            .then((res) => {
              if (res.data.code === 200) {
                this.$message.success("删除成功");
                this.getReportList();
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        },
        onCancel() {},
      });
    },
  },
  created() {
    this.getReportList();
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";
@import "@/assets/styles/flex.scss";

// 表格
.report-container {
  ::v-deep .aft-table.default.ant-table-wrapper .ant-table-header {
    margin-bottom: -4px !important;
  }
}
::v-deep .ant-table-thead > tr > th,
::v-deep .ant-table-tbody > tr > td {
  &:first-child {
    padding-left: 24px !important;
  }
}
::v-deep .ant-table-tbody > tr > td {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  cursor: default;
}
.aft-table.ant-table-wrapper {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.report-container {
  position: relative;
  padding: 20px;
  .header {
    width: 100%;
    height: 140px;
    background: linear-gradient(90deg, #303546 0%, #232733 100%);
    border-radius: 8px 8px 8px 8px;
    position: relative;
    overflow: hidden;

    .report-top {
      width: 437px;
      height: 105px;
      position: absolute;
      top: 0;
      left: 162px;
    }
    .report-bottom {
      width: 213px;
      height: 68px;
      position: absolute;
      bottom: 0;
      left: 378px;
    }

    .report-header-text {
      margin-left: 30px;
      z-index: 1;
      .report-title {
        font-size: 24px;
        @include font_color("font_color58");
      }
      .report-sub-title {
        @include font_color("font_color59");
        margin-top: 8px;
        display: flex;
        align-items: center;
        &.time {
          margin-top: 16px;
        }
        .line {
          margin: 0 8px;
          display: inline-block;
          width: 1px;
          height: 14px;
          background: rgba($color: #ffffff, $alpha: 0.55);
        }
      }
    }

    .report-icon {
      width: 102px;
      height: 104px;
      margin-right: 100px;
      z-index: 1;
    }
  }

  .operate-box {
    margin-top: 20px;
    margin-bottom: 16px;
    text-align: right;
  }

  .btn {
    margin-right: 16px;
    color: #ffcc55;
    cursor: pointer;
  }
}
</style>
