<template>
  <div class="data-card">
    <div class="data-card-title">{{title}}</div>
    <div class="data-card-main">
      <div class="row">
        <div class="row-item" v-if="data.length > 0">
          <span :class="['number', `number${data[0].type}`]">{{data[0].value}}</span>
          <span>{{data[0].name}}</span>
        </div>
        <div class="row-item" v-if="data.length > 1">
          <span :class="['number', `number${data[1].type}`]">{{data[1].value}}</span>
          <span>{{data[1].name}}</span>
        </div>
        <div class="row-item" v-if="data.length > 2">
          <span :class="['number', `number${data[2].type}`]">{{data[2].value}}</span>
          <span>{{data[2].name}}</span>
        </div>
        <div class="row-item" v-if="data.length > 3">
          <span :class="['number', `number${data[3].type}`]">{{data[3].value}}</span>
          <span>{{data[3].name}}</span>
        </div>
      </div>
      <div class="row">
        <div class="row-item" v-if="data.length > 4">
          <span :class="['number', `number${data[4].type}`]">{{data[4].value}}</span>
          <span>{{data[4].name}}</span>
        </div>
        <div class="row-item" v-if="data.length > 5">
          <span :class="['number', `number${data[5].type}`]">{{data[5].value}}</span>
          <span>{{data[5].name}}</span>
        </div>
        <div class="row-item" v-if="data.length > 6">
          <span :class="['number', `number${data[6].type}`]">{{data[6].value}}</span>
          <span>{{data[6].name}}</span>
        </div>
        <div class="row-item" v-if="data.length > 7">
          <span :class="['number', `number${data[7].type}`]">{{data[7].value}}</span>
          <span>{{data[7].name}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataCard",
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    title: {
      type: String,
      default: '-'
    }
  },
};
</script>

<style lang="scss" scoped>
.data-card {
  width: 100%;
  height: 100%;
  padding: 24px 30px 4px;
  &-title {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-align: center;
  }
  &-main {
    width: 100%;
    height: calc(100% - 68px);
    .row {
      width: 100%;
      height: 94px;
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      &:first-child {
        border-bottom: 1px solid #3c4252;
      }
      &-item {
        width: 50%;
        height: 94px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > span:first-child {
          font-size: 18px;
          font-weight: bold;
          color: #ffca5c;
          margin-bottom: 8px;
          &.number1 {
            color: #ffca5c;
          }
          &.number2 {
            color: #d0d6e6;
          }
        }
        > span:last-child {
          font-size: 14px;
          color: #9ca8ba;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
    }
  }
}
</style>
