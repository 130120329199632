import filters from '@/filters'
let color = ['#E6704E', '#F8AC68', '#F8E080', '#389C90', '#38869C', '#535A6E'];

var option = {
    backgroundColor: 'rgba(0,0,0,0)',
    color: color,
    tooltip: {
        trigger: 'item',
        formatter: '{b}: {d}%'
    },
    series: [{
        type: 'pie',
        minAngle: 15,
        startAngle: 150,
        radius: ['45%', '65%'],
        center: ['50%', '53%'],
        data: [],
        // hoverAnimation: false,
        itemStyle: {
            normal: {
                borderColor: 'rgba(0,0,0,0)',
                borderWidth: 2
            }
        },
        labelLine: {
            length: 10,
            length2: 15,
        },
        label: {
            alignTo: 'edge',
            minMargin: 5,
            normal: {
                formatter: params => {
                    return (
                        '{name|' + params.name + '}\n{value|' + filters.numRate2(params.value) + '}'
                    );
                },
                padding: [0, -10, 0, -10],
                rich: {
                    name: {
                        lineHeight: 13,
                    },
                    value: {
                        lineHeight: 17,
                        fontWeight: 'bold'
                    },
                }
            }
        },
    }]
}

export default option