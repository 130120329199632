<template>
  <a-drawer
    placement="right"
    width="560"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <div class="drawer-container">
      <div class="drawer-title">
        <span>{{ type == "add" ? "新建" : "编辑" }}知识库</span>
        <a-icon type="close" class="close-icon" @click="onClose" />
      </div>
      <div class="drawer-main">
        <a-form-model
          :model="form"
          :label-col="{ span: 24 }"
          :wrapper-col="{ span: 24 }"
          ref="ruleForm"
          :rules="rules"
        >
          <a-form-model-item
            label="内容名称"
            prop="name"
            style="margin-top: 24px"
          >
            <a-input
              v-model="form.name"
              class="aft-input drawer"
              placeholder="请输入内容名称"
              style="width: 512px"
              :maxLength="20"
            >
              <span slot="suffix" style="color: #8890a6"
                >{{ form.name.length }}/20</span
              >
            </a-input>
            <span class="input-label">用于聚金塔识别，风火轮端不显示</span>
          </a-form-model-item>

          <a-form-model-item label="标签" prop="type">
            <a-radio-group v-model="form.type">
              <a-radio
                :value="item.id"
                v-for="(item, index) in tagList"
                :key="index"
              >
                {{ item.tag_name }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item
            label="内容正文"
            prop="content"
            style="margin-top: 24px"
          >
            <a-input
              style="resize: none"
              class="aft-input drawer"
              v-model.trim="form.content"
              :autosize="{ minRows: 5, maxRows: 5 }"
              :maxLength="500"
              type="textarea"
            />
            <div class="textarea-number">{{ form.content.length }}/500</div>
          </a-form-model-item>

          <a-form-model-item
            label="内容附件"
            prop="fileList1"
            style="position: relative"
          >
            <a-upload
              :custom-request="customRequest"
              accept="image/png, image/jpg, image/jpeg, image/webp, video/mp4, .pdf, .pptx, .docx, .xlsx, .zip"
              list-type="text"
              :file-list="form.fileList1"
              :before-upload="beforeUploadImg1"
              @change="handleChange1"
              class="aft-upload-list drawer"
              :multiple="true"
            >
              <a-button class="upload-button">
                <a-icon type="upload" /> 上传文件
              </a-button>
            </a-upload>
            <div class="upload-label">
              <p>支持 jpg / png / mp4 / pptx / docx / xlsx / pdf / zip 格式</p>
              <p>单个文件大小不超过200MB</p>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="drawer-tools">
        <span class="btn btn1" @click="onClose">取消</span>
        <a-button
          class="btn btn2"
          @click="drawerSubmit"
          :loading="isAddGroupLoading"
          >确定</a-button
        >
      </div>
    </div>
  </a-drawer>
</template>

<script>
import ossUploadMixin from "@/mixins/ossUploadMixin";
import api from "@/api/knowledge";
import deepClone from "@/utils/clone-utils";
import SparkMD5 from "spark-md5";

const defaultForm = {
  name: "",
  type: "",
  content: "",
  fileList1: [],
};
export default {
  mixins: [ossUploadMixin],
  components: {},
  props: {
    tagList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      visible: false,
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
      form: deepClone(defaultForm),
      rules: {
        name: {
          required: true,
          message: "请输入内容名称",
          trigger: "blur",
        },
        type: {
          required: true,
          message: "请选择至少一个标签",
          trigger: "change",
        },
      },
      isAddGroupLoading: false,
      type: "add",
      currentItem: {},
      upload_file_params: {
        business_code: "fhl", //所属业务组编码(风火轮：fhl  聚金塔：jjt 百夫长：bfz 数据组-微控 vkong-new)
        need_compress: 2, //是否需要压缩处理-针对图片(1:是  2:否  默认是)
        type: 1, //文件类型(1:图片文件,2:音频文件,3:视频文件,4:文本文件,5:文档文件,6:emoji,7:其他)
      },
    };
  },
  created() {},
  methods: {
    showDrawer(type, currentItem) {
      this.type = type;
      this.currentItem = currentItem;
      if (this.type == "add") {
        this.form = deepClone(defaultForm);
        this.form.type = this.tagList[0].id;
      } else {
        // todo 编辑回写
        console.log("this.currentItem", this.currentItem);
        let item = deepClone(this.currentItem);
        this.form = {
          name: item.title,
          type: item.tag_id,
          content: item.content,
          fileList1: [],
        };
        if (item.material_url && item.material_url.length > 0) {
          this.form.fileList1 = item.material_url.map((item) => {
            return {
              uid: item.id,
              name: item.file_name,
              status: "done",
              // url: item.material_url,
              response: { fileId: item.video_id, url: item.material_url },
            };
          });
        }
      }
      this.visible = true;
    },
    handleChange1({ fileList }) {
      console.log("handleChange", fileList);
      if (fileList.length > 9) {
        this.$message.error("最多上传9个文件!");
      }
      this.form.fileList1 = fileList.slice(0, 9);
    },
    // 获取文件type类型
    getFileType(url) {
      //后缀获取
      let suffix = "";
      // 获取类型结果
      let imgResult = "";
      let videoResult = "";
      const flieArr = url.split("/");
      suffix = flieArr[flieArr.length - 1];
      if (suffix != "") {
        suffix = suffix.toLocaleLowerCase();
        const imgList = ["png", "jpg", "jpeg", "webp"];
        const videoList = ["mp4"];
        // 进行匹配
        imgResult = imgList.find((item) => item === suffix);
        videoResult = videoList.find((item) => item === suffix);
        if (imgResult) {
          return 1;
        } else if (videoResult) {
          return 3;
        }
      }
      return 7;
    },
    // 获取文件type类型
    getFileType2(url) {
      //后缀获取
      let suffix = "";
      // 获取类型结果
      let imgResult = "";
      let videoResult = "";
      const flieArr = url.split(".");
      suffix = flieArr[flieArr.length - 1];
      if (suffix != "") {
        suffix = suffix.toLocaleLowerCase();
        const imgList = ["png", "jpg", "jpeg", "webp"];
        const videoList = ["mp4"];
        // 进行匹配
        imgResult = imgList.find((item) => suffix.indexOf(item) >= 0);
        videoResult = videoList.find((item) => suffix.indexOf(item) >= 0);
        if (imgResult) {
          return 1;
        } else if (videoResult) {
          return 2;
        }
      }
      return 3;
    },
    async customRequest({ file, onSuccess, onProgress }) {
      console.log("customRequest", file);
      const formData = new FormData();
      formData.append("business_code", this.upload_file_params.business_code);
      formData.append("need_compress", this.upload_file_params.need_compress);
      formData.append("type", this.getFileType(file.type));
      formData.append("file", file);
      console.log(file.type.indexOf("mp4"));
      if (file.type.indexOf("mp4") >= 0) {
        const identifier = await this.computeMD5(file);

        await this.normalUploadVideo(
          file,
          this.createUploader,
          {
            appId: "fhl-admin-vod",
            businessCodeId: "1112170867613978627",
            filePath: "/风火轮app/圈子",
            fileName: file.name,
            fileTitle: file.name.replace(/(\S+)\.\w+/, "$1"),
            identifier,
          },
          this.uploadError
        );
      } else {
        await this.normalUploadFile(
          file,
          onSuccess,
          formData,
          this.uploadError
        );
      }
    },
    uploadError(file, err) {
      console.log("uploadError file", file);
      console.log("uploadError", err);
      console.log("当前上传列表", this.form.fileList1);
      this.form.fileList1.map((item) => {
        if (file.uid == item.uid) {
          item.status = "error";
        }
        return item;
      });
    },
    beforeUploadImg1(file) {
      console.log(file, this.form.fileList1);
      if (this.form.fileList1.length >= 9) {
        // this.$message.error("最多上传9个文件!");
        return false;
      }

      const isLt1M = file.size / 1024 / 1024 <= 200;
      if (!isLt1M) {
        this.$message.error("上传文件不能超过200M!");
      }
      if (!isLt1M) {
        setTimeout(() => {
          this.form.fileList1.pop();
        }, 200);
      }

      return isLt1M;
    },

    // 计算MD5
    computeMD5(file) {
      return new Promise((resolve, reject) => {
        const spark = new SparkMD5.ArrayBuffer();
        const fileReader = new FileReader();

        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
          spark.append(e.target.result);
          resolve(spark.end());
        };
        fileReader.onerror = (err) => {
          reject(err);
        };
      });
    },

    // 活动弹窗-取消
    onClose() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
      });
      this.visible = false;
    },
    // 活动弹窗-确定
    async drawerSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        try {
          console.log(this.form);
          if (valid) {
            console.log(this.form);
            // 处理参数
            let body = {
              content: this.form.content,
              title: this.form.name,
              tag_id: this.form.type,
              material_url: [],
            };
            let uploadingBool = false;
            this.form.fileList1.forEach((item) => {
              if (item.status == "uploading") {
                uploadingBool = true;
              }
              if (item.status == "done") {
                body.material_url.push({
                  file_name: item.name,
                  file_type: this.getFileType2(item.response.url),
                  url: item.response.url,
                  video_id: this.getFileType2(item.response.url) == 2 ? item.response.fileId : '',
                });
              }
            });
            if (uploadingBool) {
              this.$message.warning("当前还有未上传完成的文件，请稍后提交");
              return false;
            }
            console.log("this.currentItem", this.currentItem);
            // 修改时添加id
            if (this.type == "edit" && this.currentItem.id) {
              body.id = this.currentItem.id;
            }

            console.log(body);
            this.isAddGroupLoading = true;

            api
              .saveOrUpdateKnowledge(body)
              .then((res) => {
                console.log("saveOrUpdateKnowledge", res);
                if (res.data.code === 0) {
                  this.$message.success("提交成功");
                  this.onClose();
                  this.$emit("getList", true);
                } else if (res.data.code === 1) {
                  this.$message.error(res.data.msg || "提交失败");
                } else {
                  this.$message.error(res.message || "提交失败");
                }
              })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                this.isAddGroupLoading = false;
              });
          } else {
            this.$message.error("校验不通过,无法提交");
          }
        } catch (err) {
          console.error(err);
        }
      });
    },
    // 获取上传视频凭证

    // 视频上传方法
    createUploader(res, file) {
      console.log("createUploader", res, file);
      let self = this;

      let uploader = new AliyunUpload.Vod({
        timeout: self.timeout || 60000,
        partSize: Math.round(self.partSize || 1048576),
        parallel: self.parallel || 5,
        retryCount: self.retryCount || 1,
        retryDuration: self.retryDuration || 2,
        userId: res.userId,
        // 添加文件成功
        addFileSuccess: function (uploadInfo) {
          console.log(self.form);
          console.log("addFileSuccess: " + uploadInfo.file.name);
        },
        // 开始上传
        onUploadstarted: function (uploadInfo) {
          console.log("uploadInfo", uploadInfo);
          uploader.setUploadAuthAndAddress(
            uploadInfo,
            res.uploadAuth,
            res.uploadAddress,
            res.videoId
          );
        },
        // 文件上传成功
        onUploadSucceed: function (uploadInfo) {
          console.log(
            "onUploadSucceed: " +
              uploadInfo.file +
              ", endpoint:" +
              uploadInfo.endpoint +
              ", bucket:" +
              uploadInfo.bucket +
              ", object:" +
              uploadInfo.object
          );
          file.status = "done";
          self.form.fileList1 = self.form.fileList1.map(item => {
            if (file.uid == item.uid) {
              let newItem = item
              newItem.status = 'done'
              newItem.response = {
                url: `${res.cdnDomain}/${res.objectName}`,
                fileId: res.videoId
              }
              return newItem
            } else {
              return item
            }
          })
        },
        // 文件上传失败
        onUploadFailed: function (uploadInfo, code, message) {
          console.log(
            "onUploadFailed: file:" +
              uploadInfo.file.name +
              ",code:" +
              code +
              ", message:" +
              message
          );
        },
        // 取消文件上传
        onUploadCanceled: function (uploadInfo, code, message) {
          console.log(
            "Canceled file: " +
              uploadInfo.file.name +
              ", code: " +
              code +
              ", message:" +
              message
          );
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: function (uploadInfo, totalSize, progress) {
          // console.log(
          //   "onUploadProgress:file:" +
          //     uploadInfo.file.name +
          //     ", fileSize:" +
          //     totalSize +
          //     ", percent:" +
          //     Math.ceil(progress * 100) +
          //     "%"
          // );
          // let progressPercent = Math.ceil(progress * 100);
          // self.authProgress = progressPercent;
          // self.statusText = "文件上传中...";
        },
        // 全部文件上传结束
        onUploadEnd: function (uploadInfo) {
          console.log("onUploadEnd: uploaded all the files");
          // self.statusText = "文件上传完毕";
        },
      });
      uploader.addFile(file);
      uploader.startUpload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
/deep/ .ant-drawer-body {
  padding: 0;
}
/deep/ .ant-input {
  height: 40px;
}
.drawer-container {
  width: 100%;
  height: 100vh;
  background: #1a1d26;
  .drawer-title {
    width: 100%;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    .close-icon {
      cursor: pointer;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.65);
    }
  }
  .drawer-main {
    width: 100%;
    height: calc(100% - 116px);
    padding: 0 24px;
    overflow-y: auto;
    .upload-label {
      font-size: 14px;
      color: #8890a6;
      line-height: 22px;
      position: absolute;
      top: 32px;
      > p {
        margin-bottom: 0;
      }
    }
    .input-label {
      font-size: 14px;
      color: #545967;
      line-height: 22px;
    }
    .textarea-number {
      text-align: right;
      font-size: 14px;
      color: #8890a6;
      line-height: 22px;
    }
    .upload-button {
      width: 106px;
      height: 32px;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      background: #1a1d26;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.65);
      margin-bottom: 52px;
    }
  }
  .drawer-tools {
    width: 100%;
    height: 60px;
    padding: 0 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      width: 88px;
      height: 40px;
      border-radius: 20px;
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      &.btn1 {
        background: #2b2f3b;
        color: rgba(255, 255, 255, 0.65);
      }
      &.btn2 {
        background: #ffca5b;
        color: #000000;
        margin-left: 8px;
      }
    }
  }
}
</style>