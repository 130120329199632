var option = {
  legend: {
      show: true,
      top: '20',
      textStyle: {
          color: '#8890A6'
      },
      itemWidth: 6,
      itemHeight: 6,
      icon: 'circle',
      data:[]
  },
  grid: {  
    top: '90',
    left: '1%',  
    right: '5%',  
    bottom: '5%',  
    containLabel: true  
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    axisLabel: {
      show: true,
        textStyle: {
          color: '#8890A6',  //更改坐标轴文字颜色
          fontSize : 12     //更改坐标轴文字大小
        }
    },
    axisLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.1)',
      }
    },
    data: [],
  },
  yAxis: {
    type: "value",
    splitNumber: 4, 
    axisTick: {
      inside: false,
    },
    axisLabel: {
      show: true,
        textStyle: {
          color: '#8890A6',  //更改坐标轴文字颜色
          fontSize : 12      //更改坐标轴文字大小
        }
    },
    axisLine: {
      show: false,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "solid",
        color: 'rgba(255, 255, 255, 0.04)'
      },
    },
  },
  tooltip:{
    show: true,
    trigger: 'axis',
    backgroundColor: '#3C4252',
    textStyle: {
        color: '#fff',
    },
  },
  series: []
}

  export default option