<template>
  <div class="effect-detail-container">
    <!-- 顶部 返回按钮 基本信息介绍 -->
    <DetailTitle :skewData="skewData" :rightValue="dateStr" />
    <!-- 统计数据卡片 -->
    <StatisticsCard
      :title="'数据趋势'"
      :cardData="cardData"
      :tooltip="true"
      :code="'xxqt01'"
    />
    <!-- 趋势图 -->
    <div class="overview">
      <div class="overview-left">
        <OverviewCard
          :title="'视频概览'"
          :tabData="overviewCardData"
          @changeTab="getOverviewCardOptions"
          :options="lineChartOption"
        />
      </div>
      <div class="overview-right">
        <OverviewSelectCard
          :title="'视频分布'"
          :selectData="overviewSelectCardData"
          @changeSelect="overviewSelectCardChange"
          :options="barChartOption"
        />
      </div>
    </div>
    <!-- 最佳主播 -->
    <BestAuthor
      :title="'最佳主播'"
      @changeTab="bestAuthorChangeTab"
      :list="bestAuthorData"
      :type="'video'"
    />
    <!-- 直播增粉 -->
    <HotVideo
      :title="'最热视频'"
      @changeTab="bestVideoChangeTab"
      :list="bestVideoData"
      :type="'video'"
    />
  </div>
</template>

<script>
import DetailTitle from "./components/DetailTitle";
import StatisticsCard from "./components/StatisticsCard";
import OverviewCard from "@/components/OverviewCard/OverviewCard";
import OverviewSelectCard from "@/components/OverviewCard/OverviewSelectCard";
import deepClone from "@/utils/clone-utils";
import chartLine from "./options/chartLine.js";
import chartBar from "./options/chartBar.js";
import BestAuthor from "./components/BestAuthor";
import HotVideo from "./components/HotVideo";

import api from "@/api/effectDetail.js";
// 统计数据卡片展示文案
const cardData = {
  day: [
    {
      name: ["视频播放", "新增", "环比"],
      data: [],
    },
    {
      name: ["视频发布", "新增", "环比"],
      data: [],
    },
    {
      name: ["视频活跃门店", "新增", "环比"],
      data: [],
    },
  ],
  week: [
    {
      name: ["视频播放", "新增", "环比"],
      data: [],
    },
    {
      name: ["视频发布", "新增", "环比"],
      data: [],
    },
    {
      name: ["视频活跃门店", "新增", "环比"],
      data: [],
    },
  ],
  month: [
    {
      name: ["视频播放", "新增", "环比"],
      data: [],
    },
    {
      name: ["视频发布", "新增", "环比"],
      data: [],
    },
    {
      name: ["视频活跃门店", "新增", "环比"],
      data: [],
    },
  ],
};
// 概览tab数据
const overviewCardData = [
  {
    name: "总播放量",
    key: "play_volume",
    value: null,
    xData: [],
    yData: [],
  },
  {
    name: "视频发布",
    key: "aweme_count",
    value: null,
    xData: [],
    yData: [],
  },
  {
    name: "活跃门店",
    key: "dealer_count",
    value: null,
    xData: [],
    yData: [],
  },
];
// 门店分布下拉列表数据
const overviewSelectCardData = [
  {
    name: "视频-播放量分布",
    key: "play",
  },
  {
    name: "视频-点赞量分布",
    key: "like",
  },
  {
    name: "视频-评论量分布",
    key: "comment",
  },
  {
    name: "视频-转发量分布",
    key: "share",
  },
];
export default {
  name: "effectDetailVideo",
  components: {
    DetailTitle,
    StatisticsCard,
    OverviewCard,
    OverviewSelectCard,
    BestAuthor,
    HotVideo,
  },
  data() {
    return {
      skewData: ["视频发布"], // ['活动: T35精英门店运营', '分组: 史蒂夫', '华东大区', '2022-12周', '视频发布']
      dateStr: this.$route.query.dateStr,
      cardData: deepClone(cardData),
      // 左侧图表卡片
      overviewCardData,
      lineChartOption: {},
      // 右侧图表卡片
      overviewSelectCardData,
      overviewSelectCardChartData: {},
      barChartOption: {},
      // 最佳主播
      bestAuthorData: {},
      // 直播增粉
      bestVideoData: {},
    };
  },
  mounted() {},
  computed: {},
  created() {
    this.setSkewData();
    this.getDataTrend();
    this.getOverview();
    this.getDistribute();
    this.getBestAuthor();
    this.getBestLive();
  },
  methods: {
    // 设置顶部标题
    setSkewData() {
      console.log(this.$route.query);
      this.skewData = ["视频发布"];
      // 大区
      if (this.$route.query.rId && this.$route.query.rName) {
        this.skewData.unshift(`${decodeURIComponent(this.$route.query.rName)}`);
      }
      // 活动
      if (this.$route.query.aId && this.$route.query.aName) {
        this.skewData.unshift(
          `活动: ${decodeURIComponent(this.$route.query.aName)}`
        );
      }
    },
    // 获取页面接口通用参数
    getParams() {
      let body = {
        platform: this.$route.query.platform,
        params: {
          id: this.$route.query.aId,
          region_id: this.$route.query.rId,
        },
      };
      return body;
    },
    // 接口请求 - 数据趋势
    getDataTrend() {
      let body = this.getParams();
      // 恢复初始数据
      this.cardData = deepClone(cardData);
      api.getDataTrendByVideo(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          let key = ["day", "week", "month"];
          key.forEach((item) => {
            this.cardData[item][0].data.push(data[item].aweme_play_total);
            this.cardData[item][0].data.push(data[item].aweme_play_di);
            this.cardData[item][0].data.push(data[item].aweme_play_chain);
            this.cardData[item][1].data.push(data[item].aweme_count_total);
            this.cardData[item][1].data.push(data[item].aweme_count_di);
            this.cardData[item][1].data.push(data[item].aweme_count_chain);
            this.cardData[item][2].data.push(
              data[item].aweme_active_dealer_total
            );
            this.cardData[item][2].data.push(
              data[item].aweme_active_dealer_count
            );
            this.cardData[item][2].data.push(
              data[item].aweme_active_dealer_chain
            );
          });
        } else {
          console.error("获取数据趋势失败");
        }
      });
    },
    // 接口请求 - 粉丝概览
    getOverview() {
      let body = this.getParams();
      // 恢复初始数据
      this.overviewCardData = deepClone(overviewCardData);
      api.getOverviewByVideo(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data || [];
          this.overviewCardData.forEach((i, idx) => {
            data[`${i.key}_action_day`].forEach((item) => {
              this.overviewCardData[idx].xData.push(item.date);
              this.overviewCardData[idx].yData.push(item.value);
            });
            this.overviewCardData[idx].value = data[i.key];
          });
          this.getOverviewCardOptions(this.overviewCardData[0]);
        } else {
          console.error("获取粉丝概览失败");
        }
      });
    },
    // 接口请求 - 门店分布
    getDistribute() {
      let body = this.getParams();
      api.getDistributeByVideo(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.overviewSelectCardChartData = data;
          this.overviewSelectCardChange(this.overviewSelectCardData[0].key);
        } else {
          console.error("获取门店分布失败");
        }
      });
    },
    // 接口请求 - 最佳主播
    getBestAuthor() {
      let body = this.getParams();
      api.getBestAuthorByVideo(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.bestAuthorData = data;
        } else {
          console.error("获取最佳主播失败");
        }
      });
    },
    // 接口请求 - 直播增粉
    getBestLive() {
      let body = this.getParams();
      api.getBestLiveByVideo(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.bestVideoData = data;
        } else {
          console.error("获取直播增粉失败");
        }
      });
    },
    // 概览切换tab echart - line
    getOverviewCardOptions(item) {
      if (!item) {
        return false;
      }
      console.log("overviewCardChangeTab", item);
      let xData = item.xData;
      let yData = item.yData;
      let option = deepClone(chartLine);
      option.xAxis[0].data = xData;
      option.series[0].data = yData;
      this.lineChartOption = option;
    },
    overviewSelectCardChange(key) {
      console.log(11);
      console.log(this.overviewSelectCardChartData);
      console.log(key);
      console.log(this.overviewSelectCardChartData[key]);
      this.getOverviewSelectCardOptions(this.overviewSelectCardChartData[key]);
    },
    // echart - bar
    getOverviewSelectCardOptions(item) {
      if (!item) {
        return {};
      }
      console.log("--item--", item);
      let xData = item.xaxis;
      let yData = item.yaxis;
      let option = deepClone(chartBar);
      option.xAxis.data = xData;
      option.series[0].data = yData;
      console.log(option);
      this.barChartOption = option;
    },
    // 最佳主播 tab切换
    bestAuthorChangeTab(val) {
      console.log("bestAuthorChangeTab", val);
    },
    // 直播增粉 tab切换
    bestVideoChangeTab(val) {
      console.log("bestVideoChangeTab", val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.effect-detail-container {
  padding: 0 20px 20px;
  .top-title {
    width: 100%;
    height: 60px;
    background: #fff;
  }
  .overview {
    margin: 20px 0;
    height: 390px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &-left {
      height: 100%;
      width: calc(100% - 440px);
    }
    &-right {
      width: 420px;
      height: 100%;
    }
  }
}
</style>