import Nr from "@/utils/NetworldRequest";

const activityRequest = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AFANTIACTIVITY_API,
}).getAxios();

export default {
  // 获取报表列表
  getReportList(params) {
    return activityRequest.get("/acms/report/manage/pageList", { params });
  },

  // 获取底下用户列表
  getUserList() {
    return activityRequest.get("/acms/report/manage/jjtUserList");
  },

  // 获取模板列表
  getTemplateList() {
    return activityRequest.get("/acms/report/manage/template/list");
  },

  // 新增/编辑报表
  editReport(data) {
    return activityRequest.post("/acms/report/manage/update", data);
  },

  // 删除报表
  deleteReport(params) {
    return activityRequest.get("/acms/report/manage/delete", { params });
  },

  // 获取报表详情
  getReportDetail(params) {
    return activityRequest.get("/acms/report/manage/detail", { params });
  },

  // 获取报表可见范围列表
  getReportScopeUserList(params) {
    return activityRequest.get("/acms/report/manage/reportScopeList", {
      params,
    });
  },

  // 判断模板是否已使用
  reportTemplateExists(params) {
    return activityRequest.get("/acms/report/manage/reportTemplateExists", {
      params,
    });
  },
};
