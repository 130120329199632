<template>
  <div class="box">
    <h1>icon宝地</h1>
    <h3>单击触发 >> Command+C 工程师技能</h3>
    <div
      v-for="(item, index) in icons"
      :key="index"
      @click="copyIcons(item)"
      class="icons_box"
    >
      <svg-icon :icon-class="item" class="icon_style" />
      <p>{{ item }}</p>
    </div>
    <textarea id="input" class="input">这是幕后黑手</textarea>
  </div>
</template>
<script>
const requireComponent = require.context(
  "../../components/SvgIcon/svg", //组件所在目录的相对路径
  false, //是否查询其子目录
  /\w+\.svg$/ //匹配基础组件文件名的正则表达式
);
let iconsName = [];
requireComponent.keys().forEach((fileName) => {
  // 获取文件名
  let names = fileName
    .split("/")
    .pop()
    .replace(/\.\w+$/, "");
  iconsName.push(names);
});
export default {
  data() {
    return {
      icons: iconsName,
    };
  },
  methods: {
    copyIcons(name) {
      let input = document.getElementById("input");
      input.value = ` <svg-icon
            icon-class="${name}"       
        />`;
      input.select(); //选中文本
      document.execCommand("copy");
      this.$message.success('复制成功啦！')
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  margin: 50px auto;
  width: 1390px;
}
h1 {
  text-align: center;
  color:#F75252;
}
.icon_style {
  font-size: 46px;
  margin: 10px 46px;
}
.icons_box {
  display: inline-block;
  text-align: center;
}
.input {
  opacity: 0;
}
h3{
    text-align: center;
    color:#22C5E6;
}
</style>