<template>
  <div>
    <platform-header
      title="车系详情"
      ref="platformTab"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="tools">
      <a-month-picker
        v-model="searchTime"
        placeholder="Select month"
        class="aft-month-picker default"
        :disabled-date="disabledDate"
        @change="onChange"
        locale="zh-cn"
      >
        <div slot="suffixIcon">
          <svg-icon :icon-class="'range-picker'" />
        </div>
      </a-month-picker>
    </div>
    <div class="tabs">
      <span>数据趋势</span>
      <ButtonTab :tabData="buttonTabData" @tabChange="buttonTabChange" />
    </div>
    <div class="main-container">
      <div class="my-card">
        <div style="width: 340px">
          <img
            :src="trendData.image ? ossHost + trendData.image : ''"
            width="252"
            alt=""
          />
          <span class="card-series-name">{{
            trendData.series_name || "-"
          }}</span>
        </div>
        <div style="width: calc(100% - 340px)">
          <my-chart :options="lineOptions" id="seriesChart" />
        </div>
      </div>
      <div class="global-title">最热话题</div>
      <div class="topic-card">
        <div class="topic-card-left">
          <div class="topic-card-left-main">
            <div class="series-box">
              <img
                :src="trendData.image ? ossHost + trendData.image : ''"
                height="101"
                alt=""
              />
              <span class="series-name">{{
                trendData.series_name || "-"
              }}</span>
            </div>
            <div class="number-box">
              <div class="number-box-item">
                <span>{{ hotTopicData.topic_count | changeNum }}</span>
                <span>相关话题</span>
              </div>
              <div class="number-box-item">
                <span>{{ hotTopicData.aweme_count | changeNum }}</span>
                <span>相关视频</span>
              </div>
              <div class="number-box-item">
                <span>{{ hotTopicData.aweme_play_count | changeNum }}</span>
                <span>视频播放总量</span>
              </div>
            </div>
          </div>
        </div>
        <div class="split-line"></div>
        <div class="topic-card-right">
          <div
            class="list"
            v-for="(item, index) in hotTopicData.topics"
            :key="index"
            @click="topicClick(item)"
          >
            <div class="name">#{{ item.topic_name }}</div>
            <div class="number">{{ item.aweme_count | changeNum }}视频</div>
          </div>
        </div>
      </div>
      <div class="global-title">传播分析</div>
      <div class="split-card">
        <div class="split-card-item">
          <DataCardBig :data="bigCardData1" :title="'视频传播度分析'" />
        </div>
        <!-- <div class="split-card-item">
          <DataCardBig :data="cardData2" :title="'视频传播度分析'" />
        </div> -->
        <div class="split-card-item">
          <div class="split-card-item-title">TOP10视频播放量占比</div>
          <div class="chart-box">
            <MyChart :options="getPieOption(analysisData, 1)" id="chart1" />
          </div>
        </div>
      </div>
      <div class="video-card">
        <HotVideo
          :title="'最热视频'"
          :list="analysisData.topic_aweme_infos"
          :type="'video'"
        />
      </div>
      <div class="global-title">经销商贡献值</div>
      <div class="split-card">
        <div class="split-card-item">
          <DataCardBig :data="bigCardData2" :title="'经销商KOS参与度'" />
        </div>
        <!-- <div class="split-card-item">
          <DataCardBig :data="cardData4" :title="'经销商KOS贡献值·最新'" />
        </div> -->
        <div class="split-card-item">
          <div class="split-card-item-title">本品牌经销商参与比例</div>
          <div class="chart-box">
            <MyChart
              :options="getPieOption(kosKolData.kos_kol_proportion, 2)"
              id="chart2"
            />
          </div>
        </div>
      </div>
      <div class="global-title">KOL参与</div>
      <div class="split-card">
        <div class="split-card-item">
          <DataCardBig :data="bigCardData3" :title="'行业KOL参与度'" />
        </div>
        <!-- <div class="split-card-item">
          <DataCardBig :data="cardData6" :title="'行业KOL贡献值·最新'" />
        </div> -->
        <div class="split-card-item">
          <div class="split-card-item-title">各级别KOL参与比例</div>
          <div class="chart-box">
            <MyChart
              :options="getPieOption(kosKolData.kol_distributed, 3)"
              id="chart3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import api from "@/api/modelDetailN.js";
import MyChart from "@/components/MyChart/MyChart";
import ModelCard from "./components/ModelCard";
import VideoCard from "./components/VideoCard";
import chartLine from "./chartLine";
import chartPie from "./chartPie";
import deepClone from "@/utils/clone-utils";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import ButtonTab from "@/components/PlatformTab/NewButtonTab";
import DataCardBig from "@/components/card/DataCardBig";
import HotVideo from "./components/HotVideo";
// 视频传播度分析
const bigCardData1 = [
  {
    name: "视频增量",
    value: "",
    type: "1",
  },
  {
    name: "视频增量环比",
    value: "",
    type: "2",
  },
  {
    name: "视频播放增量",
    value: "",
    type: "1",
  },
  {
    name: "视频播放增量环比",
    value: "",
    type: "2",
  },
  {
    name: "传播峰值日期",
    value: "",
    type: "1",
  },
  {
    name: "视频播放量峰值",
    value: "",
    type: "2",
  },
  {},
  {},
];
// 经销商KOS参与度
const bigCardData2 = [
  {
    name: "KOS参与(10w+粉丝)",
    value: "",
    type: "1",
  },
  {},
  {},
  {},
  {
    name: "视频数量增量",
    value: "",
    type: "1",
  },
  {
    name: "视频数量占比",
    value: "",
    type: "2",
  },
  {
    name: "视频播放增量",
    value: "",
    type: "1",
  },
  {
    name: "视频播放量占比",
    value: "",
    type: "2",
  },
];
// 行业KOL参与度
const bigCardData3 = [
  {
    name: "KOL参与(10w+粉丝)",
    value: "",
    type: "1",
  },
  {},
  {},
  {},
  {
    name: "视频数量增量",
    value: "",
    type: "1",
  },
  {
    name: "视频数量占比",
    value: "",
    type: "2",
  },
  {
    name: "视频播放增量",
    value: "",
    type: "1",
  },
  {
    name: "视频播放量占比",
    value: "",
    type: "2",
  },
];

// 月度传播增量
const cardData1 = [
  {
    name: "视频增量",
    value: "",
    type: "1",
  },
  {
    name: "视频播放增量",
    value: "",
    type: "1",
  },
  {
    name: "传播峰值日期",
    value: "",
    type: "1",
  },
];
// 本月月度传播增量
const cardData2 = [
  {
    name: "视频播放增量",
    value: "",
    type: "1",
  },
  {
    name: "环比增长",
    value: "",
    type: "2",
  },
  {
    name: "视频数量增量",
    value: "",
    type: "1",
  },
  {
    name: "环比增长",
    value: "",
    type: "2",
  },
];
// 月度经销商KOS参与度
const cardData3 = [
  {
    name: "KOS参与(10w+粉丝)",
    value: "",
    type: "1",
  },
  {
    name: "视频播放增量",
    value: "",
    type: "1",
  },
  {
    name: "视频数量增量",
    value: "",
    type: "1",
  },
];
// 经销商KOS贡献值
const cardData4 = [
  {
    name: "视频总量",
    value: "",
    type: "1",
  },
  {
    name: "视频总量占比",
    value: "",
    type: "2",
  },
  {
    name: "视频播放量",
    value: "",
    type: "1",
  },
  {
    name: "视频播放量占比",
    value: "",
    type: "2",
  },
];
// 月度行业KOL参与度
const cardData5 = [
  {
    name: "参与KOL(10w+粉丝)",
    value: "",
    type: "1",
  },
  {
    name: "视频播放增量",
    value: "",
    type: "1",
  },
  {
    name: "视频数量增量",
    value: "",
    type: "1",
  },
];
// 行业KOL贡献值
const cardData6 = [
  {
    name: "视频总量",
    value: "",
    type: "1",
  },
  {
    name: "视频总量占比",
    value: "",
    type: "2",
  },
  {
    name: "视频播放量",
    value: "",
    type: "1",
  },
  {
    name: "视频播放量占比",
    value: "",
    type: "2",
  },
];
export default {
  name: "modelDetailN",
  components: {
    PlatformHeader,
    MyChart,
    ModelCard,
    VideoCard,
    ButtonTab,
    DataCardBig,
    HotVideo,
  },
  data() {
    return {
      msg: "车系详情",
      defaultAvatar: require("@/assets/img/defult-avatar.png"),
      ossHost: process.env.VUE_APP_LIVE_OSS_HOST,
      currentTab: "",
      buttonTabData: [
        {
          label: "累计视频总量",
          value: "aweme_total",
        },
        {
          label: "月度视频增量",
          value: "aweme_di",
        },
        {
          label: "累计播放总量",
          value: "play_total",
        },
        {
          label: "月度播放增量",
          value: "play_di",
        },
      ],
      currentButtonTab: "aweme_total",
      searchTime: moment(),
      disabledDate(current) {
        // Can not select days before today and today
        return (
          current.valueOf() <= moment("2022-05-31 23:59:59").valueOf() ||
          current >= moment().endOf("day")
        );
      },
      lineOptions: null,

      bigCardData1: deepClone(bigCardData1),
      bigCardData2: deepClone(bigCardData2),
      bigCardData3: deepClone(bigCardData3),
      // cardData1: deepClone(cardData1),
      // cardData2: deepClone(cardData2),
      // cardData3: deepClone(cardData3),
      // cardData4: deepClone(cardData4),
      // cardData5: deepClone(cardData5),
      // cardData6: deepClone(cardData6),
      bestVideoData: [],
      trendData: {},
      hotTopicData: {},
      kosKolData: {},
      analysisData: {},
    };
  },
  computed: {},
  created() {
    if (this.$route.query.platform) {
      this.currentTab = this.$route.query.platform;
    }
  },
  mounted() {
    if (this.$route.query.date) {
      this.searchTime = moment(this.$route.query.date);
    }
    if (this.$route.query.id) {
      this.tabChange(this.$route.query.platform);
    } else {
      this.$router.push("modelPerformance");
    }
  },
  watch: {
    trendData(val) {
      if (val) {
        this.lineOptions = this.getLineOption();
      }
    },
  },
  methods: {
    tabChange(val) {
      console.log(val);
      this.currentTab = val;
      this.loadAll(this.$route.query.date);
    },
    buttonTabChange(val) {
      this.currentButtonTab = val;
      this.lineOptions = this.getLineOption();
    },
    // 加载全部
    loadAll() {
      this.getDataTrend();
      this.getBestTopic();
      this.getDealerKol();
      this.getAnalysis();
    },
    getLineOption() {
      if (this.trendData == null) {
        return false;
      }
      let colors = [
        "rgba(230, 112, 78, 1)",
        "rgba(129, 92, 255, 1)",
        "rgba(223, 255, 92, 1)",
        "rgba(94, 242, 244, 1)",
        "rgba(255, 106, 176, 1)",
        "rgba(139, 175, 254, 1)",
        "rgba(216, 221, 239, 1)",
        "rgba(248, 172, 104, 1)",
        "rgba(231, 219, 166, 1)",
        "rgba(210, 136, 255, 1)",
        "rgba(0, 218, 97, 1)",
        "rgba(230, 112, 78, 1)",
        "rgba(129, 92, 255, 1)",
        "rgba(223, 255, 92, 1)",
        "rgba(94, 242, 244, 1)",
      ];
      let option = deepClone(chartLine);
      option.legend.data = this.trendData.nameArr;
      option.xAxis.data = this.trendData.timeArr;
      this.trendData.dataArr[this.currentButtonTab].forEach((item, index) => {
        option.series.push({
          type: "line",
          symbol: "circle",
          showSymbol: false,
          smooth: true,
          name: this.trendData.nameArr[index],
          itemStyle: {
            normal: {
              color:
                this.trendData.series_name == this.trendData.nameArr[index]
                  ? "rgba(255, 202, 92, 1)"
                  : colors[index],
            },
          },
          lineStyle: {
            color:
              this.trendData.series_name == this.trendData.nameArr[index]
                ? "rgba(255, 202, 92, 1)"
                : colors[index],
            opacity:
              this.trendData.series_name == this.trendData.nameArr[index]
                ? 1
                : 0.2,
          },
          data: item,
        });
      });
      console.log(option);
      return option;
    },
    getPieOption(data, type) {
      console.log(data);
      if (!data) {
        return {};
      }
      let option = deepClone(chartPie);
      let newData = [];
      if (type == 1) {
        option.color = ["#815CFF", "#3C4252"];
        newData = [
          {
            name: "Top10视频",
            value: data.top10_play_proportion,
          },
          {
            name: "其他视频",
            value: data.other_proportion,
          },
        ];
      } else if (type == 2) {
        option.color = ["#FFCA5C", "#3C4252"];
        newData = [
          {
            name: "经销商KOS",
            value: data.kos_count_ratio,
          },
          {
            name: "行业KOL",
            value: data.kol_count_ratio,
          },
        ];
      } else if (type == 3) {
        newData = [
          {
            name: "<=1w",
            value: data.level10_ratio,
          },
          {
            name: "1w+",
            value: data.level20_ratio,
          },
          {
            name: "10w+",
            value: data.level30_ratio,
          },
          {
            name: "50w+",
            value: data.level40_ratio,
          },
          {
            name: "100w+",
            value: data.level50_ratio,
          },
        ];
      }
      option.series[0].data = newData;
      return option;
    },
    // 日期切换
    onChange(date, dateString) {
      this.loadAll();
    },
    getParams() {
      let body = {
        platform: this.currentTab,
        params: {
          seriseId: this.$route.query.id,
          serise_id: this.$route.query.id,
          start_time: moment(this.searchTime)
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss"),
          end_time: moment(this.searchTime)
            .endOf("month")
            .format("YYYY-MM-DD HH:mm:ss"),
        },
      };
      return body;
    },
    // 数据趋势
    getDataTrend() {
      let body = this.getParams();
      api.getDataTrend(body).then((res) => {
        if (res.data.code === 0) {
          let data = {
            ...res.data.data,
            nameArr: [],
            timeArr: [],
            dataArr: {
              aweme_total: [],
              aweme_di: [],
              play_total: [],
              play_di: [],
            },
          };
          console.log("getDataTrend", data);
          if (data.seriesday_infos && data.seriesday_infos.length > 0) {
            data.nameArr = data.seriesday_infos.map((item) => {
              return item.series_name;
            });
            data.timeArr = data.seriesday_infos[0].interactive_chart.map(
              (item) => {
                return item.date;
              }
            );
            this.buttonTabData.forEach((item) => {
              data.seriesday_infos.forEach((i) => {
                let newArr = i.interactive_chart.map((d) => {
                  return d[item.value];
                });
                data.dataArr[item.value].push(newArr);
              });
            });
          }
          this.trendData = data;
          console.log(1232132, data);
        } else {
          console.error("获取数据趋势失败");
        }
      });
    },
    // 最热话题
    getBestTopic() {
      let body = this.getParams();
      api.getBestTopic(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          console.log("getBestTopic", data);
          this.hotTopicData = data;
        } else {
          console.error("获取最热话题失败");
        }
      });
    },
    // 经销商参与 & KOL
    getDealerKol() {
      let body = this.getParams();
      api.getDealerKol(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          console.log("getDealerKol", data);
          this.kosKolData = data;
          // kol
          // this.cardData5[0].value = this.$options.filters.changeNum(data.kol.count);
          // this.cardData5[1].value = this.$options.filters.changeNum(data.kol.aweme_play_di);
          // this.cardData5[2].value = this.$options.filters.changeNum(data.kol.aweme_di);
          // this.cardData6[0].value = this.$options.filters.changeNum(data.kol.aweme_total);
          // this.cardData6[1].value = this.$options.filters.numRate(data.kol.aweme_ratio);
          // this.cardData6[2].value = this.$options.filters.changeNum(data.kol.aweme_play_total);
          // this.cardData6[3].value = this.$options.filters.numRate(data.kol.aweme_play_ratio);

          this.bigCardData3[0].value = this.$options.filters.changeNum(
            data.kol.count
          );
          this.bigCardData3[4].value = this.$options.filters.changeNum(
            data.kol.aweme_di
          );
          this.bigCardData3[5].value = this.$options.filters.numRate(
            data.kol.aweme_ratio
          );
          this.bigCardData3[6].value = this.$options.filters.changeNum(
            data.kol.aweme_play_di
          );
          this.bigCardData3[7].value = this.$options.filters.numRate(
            data.kol.aweme_play_ratio
          );
          // kos
          // this.cardData3[0].value = this.$options.filters.changeNum(data.kos.count);
          // this.cardData3[1].value = this.$options.filters.changeNum(data.kos.aweme_play_di);
          // this.cardData3[2].value = this.$options.filters.changeNum(data.kos.aweme_di);
          // this.cardData4[0].value = this.$options.filters.changeNum(data.kos.aweme_total);
          // this.cardData4[1].value = this.$options.filters.numRate(data.kos.aweme_ratio);
          // this.cardData4[2].value = this.$options.filters.changeNum(data.kos.aweme_play_total);
          // this.cardData4[3].value = this.$options.filters.numRate(data.kos.aweme_play_ratio);

          this.bigCardData2[0].value = this.$options.filters.changeNum(
            data.kos.count
          );
          this.bigCardData2[4].value = this.$options.filters.changeNum(
            data.kos.aweme_di
          );
          this.bigCardData2[5].value = this.$options.filters.numRate(
            data.kos.aweme_ratio
          );
          this.bigCardData2[6].value = this.$options.filters.changeNum(
            data.kos.aweme_play_di
          );
          this.bigCardData2[7].value = this.$options.filters.numRate(
            data.kos.aweme_play_ratio
          );
        } else {
          console.error("获取经销商参与 & KOL失败");
        }
      });
    },
    // 传播分析
    getAnalysis() {
      let body = this.getParams();
      api.getAnalysis(body).then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          console.log("getAnalysis", data, data.aweme_di);
          this.analysisData = data;

          this.bigCardData1[0].value = this.$options.filters.changeNum(
            data.aweme_di
          );
          this.bigCardData1[1].value = this.$options.filters.numRate(
            data.aweme_chain_ratio
          );
          this.bigCardData1[2].value = this.$options.filters.changeNum(
            data.aweme_play_di
          );
          this.bigCardData1[3].value = this.$options.filters.numRate(
            data.play_chain_ratio
          );
          this.bigCardData1[4].value = data.peak_date || "-";
          this.bigCardData1[5].value = this.$options.filters.changeNum(
            data.max_play_value
          );

          // this.cardData1[0].value = this.$options.filters.changeNum(data.aweme_di);
          // this.cardData1[1].value = this.$options.filters.changeNum(data.aweme_play_di);
          // this.cardData1[2].value = data.peak_date;
          // this.cardData2[0].value = this.$options.filters.changeNum(data.aweme_play_di);
          // this.cardData2[1].value = this.$options.filters.numRate(data.play_chain_ratio);
          // this.cardData2[2].value = this.$options.filters.changeNum(data.aweme_di);
          // this.cardData2[3].value = this.$options.filters.numRate(data.aweme_chain_ratio);
        } else {
          console.error("获取传播分析失败");
        }
      });
    },
    // 点击跳转车系话题详情
    topicClick(item) {
      console.log(item);
      console.log("车系id", this.$route.query.id);
      let routeUrl = `/model/modelTopicDetail?id=${
        this.$route.query.id
      }&topicId=${item.topic_id}&platform=${this.currentTab}&date=${moment(
        this.searchTime
      ).format("YYYY-MM")}`;
      window.open(routeUrl, "_blank");
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
}
.title {
  font-weight: bold;
  font-size: 20px;
  padding: 20px 24px;
  @include font_color("font_color2");
}
.card-title-icon {
  @include font_color("font_color5");
  font-size: 18px;
  margin: 0 8px;
}
.tools {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 24px;
}
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 24px;
  height: 40px;
  > span {
    font-size: 16px;
    font-weight: bold;
    margin-right: 20px;
    @include font_color("font_color20");
  }
}
.main-container {
  width: 100%;
  padding: 0 20px 20px;
  .global-title {
    height: 75px;
    padding: 30px 0 20px;
    @include font_color("font_color20");
    font-size: 18px;
    font-weight: bold;
  }
  .topic-card {
    width: 100%;
    height: 235px;
    @include background_color("background_color2");
    border-radius: 16px;
    padding: 30px 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    &-left {
      width: 50%;
      height: 100%;
      &-main {
        width: 471px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .series-box {
          height: 101px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 0 0 1px;
          > span {
            color: #d0d6e6;
            font-size: 16px;
            margin-left: 20px;
          }
        }
        .number-box {
          width: 100%;
          height: 47px;
          display: flex;
          &-item {
            width: 157px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            > span:first-child {
              font-size: 18px;
              font-weight: bold;
              color: #ffca5c;
            }
            > span:last-child {
              font-size: 12px;
              color: #8890a6;
            }
          }
        }
      }
    }
    .split-line {
      width: 1px;
      height: 100%;
      background: #3c4252;
    }
    &-right {
      width: 50%;
      height: 100%;
      overflow-y: auto;
      padding: 0 38px 0 30px;
      > .list {
        width: 100%;
        height: 20px;
        margin-bottom: 17px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        cursor: pointer;
        &:hover {
          > .name,
          > .number {
            color: #ffc05c;
            text-decoration: underline;
          }
        }
        > .name {
          font-size: 14px;
          font-weight: bold;
          color: #d0d6e6;
          width: calc(100% - 150px);
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }
        > .number {
          font-size: 14px;
          font-weight: bold;
          color: #565d72;
          width: 150px;
          text-align: right;
        }
      }
    }
  }
  .split-card {
    width: 100%;
    height: 237px;
    display: flex;
    &-item {
      @include background_color("background_color2");
      border-radius: 16px;
      height: 100%;
      &-title {
        width: 100%;
        height: 68px;
        line-height: 68px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
      }
      .chart-box {
        width: 100%;
        height: calc(100% - 68px);
      }
      &:first-child {
        flex: 2;
      }
      &:last-child {
        flex: 1;
        margin-left: 20px;
      }
    }
  }
  .video-card {
    @include background_color("background_color2");
    border-radius: 16px;
    height: 272px;
    margin-top: 20px;
  }
  .my-card {
    @include background_color("background_color81");
    height: 268px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    @include font_color("font_color21");
    border-radius: 16px;

    > div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .card-series-name {
      @include font_color("font_color1");
    }
  }
}
</style>
