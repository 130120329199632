import Nr from '@/utils/NetworldRequest'
const request = new Nr({
  baseURL: process.env.VUE_APP_OPENDATA_AGG_API
}).getAxios()

function getBaseUrl(serviceName) {
  return (new Nr).getBaseUrl(serviceName)
}

const platformRequest = (platform) => {
  let baseURL = getBaseUrl(platform)

  return new Nr({
    baseURL: baseURL
  }).getAxios()
}

export default {
  // 获取知识库列表
  getKnowledgeList(params) {
    return request.get('/jjt/repository/list', {params: params})
  },
  // 获取类型
  getKnowledgeType() {
    return request.get('/jjt/repository/tagList')
  },
  // 编辑、上传知识库
  saveOrUpdateKnowledge(body) {
    return request.post('/jjt/repository/insert', body)
  },
  // 删除知识库
  deleteKnowledgeById(body) {
    return request.post('/jjt/repository/deleted', body)
  },

  // 视频地址通过内容中台获取权限
  getAuthUrl(body) {
    return platformRequest('contentcenter').post('/v1/content/vod/getAuthUrl', body)
  },

}