<template>
  <a-modal
    class="new-aft-modal"
    :title="`${title}报表`"
    :visible="visible"
    :maskClosable="false"
    width="720px"
    @cancel="onClose"
  >
    <a-spin :spinning="loading">
      <a-form-model
        :model="form"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        ref="reportForm"
      >
        <a-form-model-item label="报表名称" prop="reportName">
          <a-input
            class="new-aft-input"
            v-model="form.reportName"
            placeholder="请输入名称"
            :maxLength="30"
            style="width: 100%"
          />
          <span class="count">{{ form.reportName.length || 0 }}/30</span>
        </a-form-model-item>
        <a-form-model-item ref="memberSelect" label="公开范围" prop="scopeId">
          <a-radio-group v-model="form.scope" @change="handleScopeChange">
            <a-radio :value="1">所有用户可见</a-radio>
            <a-radio :value="2">部分用户可见</a-radio>
            <a-radio :value="3">仅自己可见</a-radio>
          </a-radio-group>
          <a-select
            class="new-aft-select"
            mode="multiple"
            v-model="form.scopeId"
            :default-active-first-option="false"
            :suffixIcon="suffixIcon"
            option-filter-prop="children"
            placeholder="请选择可见成员"
            show-search
            notFoundContent="暂无数据"
            v-if="form.scope === 2"
            @change="$refs['memberSelect'].onFieldBlur()"
          >
            <a-select-option
              :value="item.userId"
              v-for="item in userList"
              :key="item.userId"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="选择模版" prop="reportTemplateId">
          <a-select
            class="new-aft-select"
            v-model="form.reportTemplateId"
            placeholder="请选择模版"
            notFoundContent="暂无数据"
            @change="checkTemplate"
          >
            <a-select-option
              :value="item.id"
              v-for="item in templateList"
              :key="item.id"
            >
              {{ item.reportTemplateName }}
            </a-select-option>
          </a-select>
          <span class="tips" v-if="isExists">
            已有此模版的报表，请确认是否再次创建
          </span>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
    <div slot="footer" class="modal-footer">
      <a-button
        class="new-aft-btn"
        type="default"
        size="large"
        @click="onClose"
      >
        取消
      </a-button>
      <a-button
        class="new-aft-btn"
        type="primary"
        size="large"
        @click="save"
        :loading="saving"
      >
        确认
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import api from "@/api/activityReport.js";
import { getPrincipalId, getUserId } from "@/utils/auth";

export default {
  components: {},
  data() {
    return {
      visible: false,
      loading: false,
      saving: false,
      form: {
        id: undefined,
        reportName: "",
        reportTemplateId: undefined,
        scope: 1,
        scopeId: [],
      },
      rules: {
        reportName: {
          required: true,
          message: "请输入报表名称，不超过30个字",
          trigger: "blur",
        },
        scopeId: {
          required: true,
          message: "请选择可见成员",
          trigger: "blur",
        },
        reportTemplateId: {
          required: true,
          message: "请选择模版",
          trigger: "change",
        },
      },
      isExists: false,

      userList: [],
      templateList: [],
    };
  },
  computed: {
    suffixIcon() {
      return (
        <svg-icon
          icon-class="select-arrow"
          style="font-size: 12px;color:#D8D8D8"
        />
      );
    },
    title() {
      return this.form.id ? "编辑" : "创建";
    },
  },
  methods: {
    /**
     * 打开弹窗
     * @param {number} id 报表id
     */
    async openModal(id) {
      Object.assign(this.$data, this.$options.data());
      this.form.id = id;
      this.visible = true;
      this.$nextTick(() => {
        this.$refs.reportForm.clearValidate();
      });
      this.loading = true;
      await this.getUserList();
      await this.getTemplateList();
      if (id) {
        await this.getReportDetail(id);
      }
      this.loading = false;
    },
    /**
     * 检查模板是否存在
     */

    async checkTemplate() {
      const res = await api.reportTemplateExists({
        reportTemplateId: this.form.reportTemplateId,
      });
      if (res.data.code === 200) {
        this.isExists = res.data.data;
      }
    },
    /**
     * 获取用户列表
     */
    async getUserList() {
      try {
        const {data: res} = await api.getUserList();
        if (res.code === 200) {
          this.userList = res.data;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
      }
    },
    /**
     * 获取模板列表
     */
    async getTemplateList() {
      try {
        const {data: res} = await api.getTemplateList();
        if (res.code === 200) {
          this.templateList = res.data;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
      }
    },
    /**
     * 获取报表详情
     * @param {number} id 报表id
     */
    async getReportDetail(id) {
      try {
        const {data: res} = await api.getReportDetail({id});
        if (res.code === 200) {
          const {
            id,
            reportName,
            reportTemplateId,
            scope,
            scopeList,
            reportTemplateIsDeleted,
            reportTemplateStatus,
          } = res.data;
          this.form = {
            id,
            reportName,
            // 如果模板被删除或者禁用, 不回显模板
            reportTemplateId:
              reportTemplateIsDeleted === 1 || reportTemplateStatus === 2
                ? undefined
                : reportTemplateId,
            scope,
            scopeId:
              scope === 1
                ? []
                : scopeList
                  .filter((item) => item.scopeId !== getUserId())
                  .map((item) => item.scopeId),
          };
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
      }
    },
    /**
     * 可见范围改变
     */
    handleScopeChange() {
      this.form.scopeId = [];
      this.$nextTick(() => {
        this.$refs["memberSelect"].clearValidate();
      });
    },
    /**
     * 弹窗-取消
     */
    onClose() {
      this.$confirm({
        icon: () => <a-icon type="info-circle" theme="filled" />,
        title: "离开后数据将会丢失，确认要离开吗？",
        onOk: () => {
          this.visible = false;
        },
        onCancel() {
        },
      });
    },
    /**
     * 保存
     */
    save() {
      if (this.form.scope === 1) {
        this.form.scopeId = [getPrincipalId()];
      } else if (this.form.scope === 3) {
        this.form.scopeId = [getUserId()];
      }
      this.$refs.reportForm.validate(async (valid) => {
        if (valid) {
          try {
            this.saving = true;
            const {data: res} = await api.editReport({
              ...this.form,
              scopeId:
                this.form.scope === 2
                  ? [getUserId(), ...this.form.scopeId]
                  : this.form.scopeId,
            });
            this.saving = false;
            if (res.code === 200) {
              this.$message.success("提交成功");
              this.$emit("success");
              this.visible = false;
            } else {
              this.$message.error(res.message);
            }
          } catch (error) {
            this.saving = false;
          }
        }
      });
    },
  },
  created() {
  },
};
</script>

<style scoped lang="scss">
.count {
  color: #8890a6;
  position: absolute;
  right: 16px;
}

.tips {
  color: #8890a6;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
