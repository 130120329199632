<template>
  <a-modal
    class="new-aft-modal"
    title="报表详情"
    :visible="visible"
    width="520px"
    @cancel="visible = false"
  >
    <a-spin :spinning="loading">
      <div class="detail-item">
        <span class="label-text">报表名称：</span>
        <span>{{ form.reportName }}</span>
      </div>
      <div class="detail-item">
        <span class="label-text">公开范围：</span>
        <span class="member-list">
          <span>{{ scopeObject[form.scope] }}</span>
          <!-- <template v-if="form.scope !== 3">
            <span>({{ form.scopeSize }})</span>
            <span class="btn" @click="openScopeUserListModal">查看名单</span>
          </template> -->
        </span>
      </div>
      <div class="detail-item">
        <span class="label-text">选择模板：</span>
        <span>{{ form.reportTemplateName }}</span>
      </div>
    </a-spin>
    <div slot="footer" class="modal-footer">
      <a-button
        class="new-aft-btn"
        type="default"
        size="large"
        @click="visible = false"
      >
        关闭
      </a-button>
    </div>

    <ScopeUserListModal ref="ScopeUserListModal" />
  </a-modal>
</template>

<script>
import api from "@/api/activityReport.js";
import ScopeUserListModal from "./ScopeUserListModal";

const scopeObject = {
  1: "所有用户可见",
  2: "部分用户可见",
  3: "仅自己可见",
};
export default {
  components: {ScopeUserListModal},
  data() {
    return {
      visible: false,
      loading: false,

      scopeObject,
      form: {
        id: undefined,
        reportName: "",
        reportTemplateName: "",
        scope: 3,
        scopeSize: 0,
      },
    };
  },
  methods: {
    async openModal(id) {
      Object.assign(this.$data, this.$options.data());
      this.visible = true;
      this.loading = true;
      await this.getReportDetail(id);
      this.loading = false;
    },
    /**
     * 获取报表详情
     * @param {number} id 报表id
     */
    async getReportDetail(id) {
      try {
        const {data: res} = await api.getReportDetail({id});
        if (res.code === 200) {
          const {
            id,
            reportName,
            reportTemplateName,
            scope,
            scopeSize,
          } = res.data;
          this.form = {
            id,
            reportName,
            reportTemplateName,
            scope,
            scopeSize,
          };
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
      }
    },
    openScopeUserListModal() {
      this.$refs["ScopeUserListModal"].openModal(this.form.id);
    },
  },
  created() {
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";

.detail-item {
  @include font_color("font_color58");

  & + .detail-item {
    margin-top: 24px;
  }

  .label-text {
    display: inline-block;
    width: 70px;
  }

  .member-list {
    & > span {
      margin-right: 8px;

      &.btn {
        margin-right: 16px;
        color: #ffcc55;
        cursor: pointer;
      }
    }
  }
}
</style>
