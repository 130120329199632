import { render, staticRenderFns } from "./focusOnStores.vue?vue&type=template&id=438cf4a7&scoped=true"
import script from "./focusOnStores.vue?vue&type=script&lang=js"
export * from "./focusOnStores.vue?vue&type=script&lang=js"
import style0 from "./focusOnStores.vue?vue&type=style&index=0&id=438cf4a7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "438cf4a7",
  null
  
)

export default component.exports