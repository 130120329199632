<template>
  <div>
    <platform-header
      ref="platformTab"
      title="经销商详情"
      :showPlatform="false"
    />
    <div class="main-container">
      <!-- 顶部经销商详情信息 -->
      <div class="dealer-box">
        <!-- info -->
        <div class="dealer-box-info">
          <span class="big-svg">
            <svg-icon icon-class="store-icon" />
          </span>
          <div class="dealer-box-info-right">
            <div>
              <span class="name">{{ detailData.dealer_name || "-" }}</span>
            </div>
            <div class="tag-list">
              <span
                class="tag tag1"
                v-for="item in detailData.group_infos"
                :key="item.group_id"
                >{{ item.group_name }}
              </span>
            </div>
            <div>
              <span class="name">{{ detailData.company_name || "-" }}</span>
              <svg-icon icon-class="store-icon" class="small-svg" />
              <span>{{ detailData.store_code }}</span>
              <svg-icon
                v-if="detailData.principal_infos"
                icon-class="area2-icon"
                class="small-svg"
              />
              <span v-if="detailData.principal_infos">{{
                detailData.principal_infos[0].area_name || "-"
              }}</span>
              <svg-icon icon-class="position-icon" class="small-svg" />
              <span
                >{{ detailData.province_name }}{{ detailData.city_name }}</span
              >
            </div>
          </div>
        </div>
        <!-- 成就 -->
        <div class="dealer-box-achievement">
          <span class="fs1">运营状态：</span>
          <span
            class="tag tag2"
            v-for="item in detailData.label_infos"
            :key="item.label_id_level_four"
            >{{ item.label_name_level_four }}</span
          >
        </div>
        <!-- 等级 -->
        <!-- <div
          :class="['dealer-box-level', `dealer-box-level-${detailData.level}`]"
        >
          <span>门店评级</span>
          <span>{{ detailData.level || "-" }}</span>
        </div> -->
      </div>
      <!-- 门店KPI -->
      <!-- <div class="kpi-box">
        <div class="title-box">
          <span class="title">门店KPI进度 · 2023年1月</span>

          <a-week-picker
            class="aft-range-picker default"
            placeholder="Select week"
            v-model="searchWeekTime"
            :disabled-date="disabledDate"
            @change="onChangeWeek"
          >
            <div slot="suffixIcon">
              <svg-icon :icon-class="'range-picker'" />
            </div>
          </a-week-picker>
        </div>
        <div class="kpi-container">
          <div class="task-pie">
            <a-progress
              type="circle"
              class="aft-progress default"
              strokeColor="#FFCA5C"
              :width="160"
              :percent="parseFloat(taskData.taskCompletionRate)"
            >
              <template #format="percent">
                <div class="number">
                  {{ taskData.taskCompletionRate || percent + "%" }}
                </div>
                <div class="text">KPI完成进度: 2/7</div>
              </template>
            </a-progress>
          </div>
          <div class="task-container">
            <div
              class="grid-container"
              ref="taskList"
              v-if="
                taskData.taskInfoVOList && taskData.taskInfoVOList.length > 0
              "
            >
              <KpiItem
                v-for="(item, index) in taskData.taskInfoVOList"
                :key="index"
                :kpiData="item"
              />
            </div>

            <div class="no-task" v-else>
              <span>暂无KPI任务</span>
            </div>

            <div
              class="scroll-button left-button"
              @click="taskScroll('left')"
              v-if="
                taskData.taskInfoVOList && taskData.taskInfoVOList.length > 0
              "
            >
              <a-icon type="left" />
            </div>
            <div
              class="scroll-button right-button"
              @click="taskScroll('right')"
              v-if="
                taskData.taskInfoVOList && taskData.taskInfoVOList.length > 0
              "
            >
              <a-icon type="right" />
            </div>
          </div>
        </div>
      </div> -->
      <div class="account-box">
        <div class="account-box-header">
          <span class="title">门店账号（{{ authorList.length || 0 }}）</span>
          <div
            class="arrow"
            @click="arrowType = !arrowType"
            v-if="authorList.length > 4"
          >
            <span>{{ arrowType ? "展开全部" : "收起" }}</span>
            <svg-icon
              icon-class="arrow-down"
              :class="arrowType ? '' : 'rotate'"
            />
          </div>
        </div>
        <div :class="['account-box-body', arrowType ? 'hide' : '']">
          <div
            class="list-item"
            v-for="(item, index) in authorList"
            :key="index"
            @click="authorClick(item)"
          >
            <div class="list-item-col">
              <WebpImage
                :src="item.avatar"
                :width="'32px'"
                :height="'32px'"
                :radius="'50%'"
                class="avatar"
              />
              <svg-icon
                class="platform-icon"
                :icon-class="`platform-color-${item.platform}`"
              />
              <svg-icon
                :icon-class="
                  item.verification_type == 2
                    ? 'account-type-blue'
                    : 'account-type-grey'
                "
                class="type-icon"
              />
              <span>{{ item.nickname }}</span>
              <span
                class="tag tag1"
                v-for="i in item.label_infos"
                :key="i.tag_id"
                >{{ i.tag_name }}</span
              >
            </div>
            <div class="list-item-col">
              <span> 粉丝总量 </span>
              <span>
                {{ item.fans_count_total | changeNum }}
              </span>
            </div>
            <div class="list-item-col">查看</div>
          </div>
          <Empty
            :title="'暂无账号'"
            v-show="!authorList || authorList.length == 0"
          />
        </div>
        <!-- <div class="shadow"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/dealerDetail";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import WebpImage from "@/components/WebpImage/WebpImage";
import ButtonTabs from "@/components/PlatformTab/NewButtonTab";
import WorksCard from "@/components/WorksCard/WorksCard";
import Empty from "@/components/Empty/Empty";
import KpiItem from "@/components/Item/KpiItem";

export default {
  name: "dealerDetailN",
  components: {
    PlatformHeader,
    WebpImage,
    ButtonTabs,
    WorksCard,
    Empty,
    KpiItem,
  },
  data() {
    return {
      msg: "经销商详情",
      detailData: {},
      authorList: [],
      arrowType: true,
      taskData: {
        taskCompletionRate: "",
        taskInfoVOList: [{}],
      },
      searchWeekTime: moment().subtract(7, "days"),
      disabledDate(current) {
        return current && current >= moment().startOf("week");
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.initData();
    } else {
      console.log("没有获取到经销商信息");
    }
  },
  computed: {},
  methods: {
    initData() {
      this.getDealerDetailN();
      this.getDealerAuthorsList();
    },

    // 日期切换
    onChangeWeek() {
      // this.getPerspectiveChart();
    },
    // 获取门店详情
    getDealerDetailN() {
      let params = {
        dealer_id: this.$route.query.id,
      };
      api.getDealerDetailN(params).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.detailData = res.data.data || {};
        } else {
          console.error("获取门店详情失败");
        }
      });
    },
    // 获取门店账号列表
    getDealerAuthorsList() {
      let params = {
        dealer_id: this.$route.query.id,
      };
      api.getDealerAuthorsList(params).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.authorList = res.data.data || [];
        } else {
          this.authorList = [];
          console.error("获取门店账号列表失败");
        }
      });
    },
    // 任务滚动
    taskScroll(type) {
      let dom = this.$refs[`taskList`];
      let position = 0;

      if (type == "left") {
        position = dom.scrollLeft - 496;
      } else if (type == "right") {
        position = dom.scrollLeft + 496;
      }

      dom &&
        dom.scrollTo({
          left: position,
          behavior: "smooth",
        });
    },
    authorClick(item) {
      if (item && item.author_id) {
        this.$router.push({
          path: "/dealer/productionN",
          query: {
            type: item.platform,
            author_id: item.author_id,
          },
        });
      } else {
        this.$message.warning("未获取到author_id!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.main-container {
  padding: 0 20px;
}
.dealer-box {
  width: 100%;
  height: 152px;
  background: #2b2f3b;
  border-radius: 16px;
  padding: 30px 0 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &-info {
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .big-svg {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      font-size: 40px;
      color: #ffca5c;
      margin-right: 8px;
      background: #403f3e;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      > div:first-child {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .name {
          font-size: 14px;
          font-weight: bold;
          color: rgba(255, 255, 255, 0.88);
          line-height: 22px;
          margin-right: 4px;
        }
      }
      > div:last-child {
        display: flex;
        align-items: center;
        > .name {
          margin-right: 8px;
        }
        > span {
          font-size: 12px;
          color: #9ca8ba;
          line-height: 20px;
        }
        > .small-svg {
          font-size: 16px;
          color: #9ca8ba;
          margin: 0 4px 0 16px;
        }
      }
    }
  }
  &-achievement {
    width: 100%;
    height: 30px;
    padding-left: 58px;
    display: flex;
    align-items: center;
    > .icon {
      font-size: 30px;
      margin-right: 20px;
    }
    .fs1 {
      font-size: 12px;
      color: #9ca8ba;
    }
    .fs2 {
      font-size: 14px;
      font-weight: bold;
      color: #ffca5c;
    }
  }
  &-level {
    width: 145px;
    height: 64px;
    border-radius: 32px 0 0 32px;
    border: 1px solid #363a4a;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 24px;
    border-right: none;
    background: #242832;
    > span:first-child {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.55);
      line-height: 22px;
      margin: 0 8px 0 24px;
    }
    > span:last-child {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 20px;
      font-weight: bold;
      line-height: 40px;
      text-align: center;
      @include font_color("font_color21");
      @include background_color("background_color55");
    }
  }
  .dealer-box-level.dealer-box-level-S > span:last-child {
    @include background_color("background_color52");
    @include font_color("font_color27");
  }
  .dealer-box-level.dealer-box-level-A > span:last-child {
    @include background_color("background_color53");
    @include font_color("font_color26");
  }
  .dealer-box-level.dealer-box-level-B > span:last-child {
    @include background_color("background_color54");
    @include font_color("font_color29");
  }
  .dealer-box-level.dealer-box-level-C > span:last-child {
    @include background_color("background_color60");
    @include font_color("font_color31");
  }
}

.kpi-box {
  > .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0 20px;
    height: 84px;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.88);
      line-height: 24px;
    }
  }
  .kpi-container {
    height: 200px;
    display: flex;
    .task-pie {
      width: 200px;
      height: 100%;
      background: #2b2f3b;
      border-radius: 16px;
      padding: 20px;
      .number {
        font-size: 24px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.88);
        line-height: 32px;
      }
      .text {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.55);
        line-height: 20px;
      }
    }
    .task-container {
      width: calc(100% - 200px);
      height: 100%;
      overflow-y: hidden;
      position: relative;
      padding: 0 0 0 16px;
      // 有数据时
      .grid-container {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background: #2b2f3b;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 0 0 20px;
        > div {
          margin-right: 8px;
        }
      }
      // 无数据时
      .no-task {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background: #2b2f3b;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > span {
          font-size: 14px;
          color: #8890a6;
          line-height: 17px;
          margin-bottom: 12px;
        }
        .search-btn {
          @include border_color("border_color4");
          @include font_color("font_color17");
          @include background_color("background_color13");
          width: 96px;
          height: 40px;
          border-radius: 8px;
        }
      }

      &:hover {
        .scroll-button {
          opacity: 1;
        }
      }

      .scroll-button {
        display: inline-block;
        position: absolute;
        top: 80px;
        width: 40px;
        height: 40px;
        background: #2b2f3b;
        box-shadow: 0px 3px 12px 4px rgba(0, 0, 0, 0.12),
          0px 2px 7px 0px rgba(0, 0, 0, 0.2),
          0px 1.5px 4px -2px rgba(0, 0, 0, 0.32);
        border-radius: 8px;
        color: rgba(255, 255, 255, 0.3);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        opacity: 0;
        &:hover {
          color: rgba(255, 255, 255, 0.88);
        }
      }
      .left-button {
        left: 7px;
      }
      .right-button {
        right: 17px;
      }
    }
  }
}

.account-box {
  position: relative;

  &-header {
    width: 100%;
    height: 64px;
    padding: 24px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 16px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.88);
    }
    .arrow {
      color: #ffcc55;
      font-size: 14px;
      cursor: pointer;
      > svg {
        transition: all 0.3s;
        margin-left: 4px;
        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }
  &-body {
    width: 100%;
    overflow-y: auto;
    &.hide {
      max-height: 328px;
      overflow-y: hidden;
    }
    > .list-item {
      width: 100%;
      height: 74px;
      background: #2b2f3b;
      border-radius: 16px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      cursor: pointer;
      > .list-item-col {
        text-align: center;
        &:nth-child(1) {
          width: 50%;
          padding-left: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          position: relative;
          .platform-icon {
            font-size: 16px;
            position: absolute;
            left: 36px;
            top: 16px;
          }
          .type-icon {
            font-size: 20px;
            margin: 0 4px 0 12px;
          }
          > span {
            font-size: 14px;
            color: #ffffff;
          }
        }
        &:nth-child(2) {
          width: 39%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          > span:first-child {
            font-size: 14px;
            color: #6a7080;
            line-height: 22px;
            margin-bottom: 4px;
          }
          > span:last-child {
            font-size: 20px;
            font-weight: bold;
            color: rgba(255, 255, 255, 0.88);
            line-height: 28px;
          }
        }
        &:nth-child(3) {
          width: 11%;
          font-size: 14px;
          color: #9ca8ba;
        }
      }
    }

    > .list-item:hover {
      background: #242832;
      > .list-item-col {
        &:nth-child(6) {
          font-weight: bold;
          color: #ffca5c;
        }
      }
    }
  }

  .shadow {
    width: 100%;
    height: 20px;
    background: linear-gradient(180deg, rgba(26, 29, 38, 0) 0%, #1a1d26 100%);
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.tag-list {
  margin-left: -3px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.tag {
  margin-bottom: 5px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  padding: 0 8px;
  border-radius: 4px;
  margin-left: 4px;
  &.tag1 {
    color: #ffcc55;
    background: rgba(255, 204, 85, 0.2);
  }
  &.tag2 {
    color: #ff9026;
    background: rgba(255, 158, 61, 0.2);
  }
}
</style>
