<template>
  <a-modal
    class="new-aft-modal"
    title="可见用户名单"
    :visible="visible"
    width="720px"
    @cancel="visible = false"
  >
    <a-table
      class="aft-table"
      :pagination="false"
      :columns="columns"
      :dataSource="list"
      :loading="loading"
      rowKey="dealerId"
      :scroll="{ y: 400 }"
      :locale="{ emptyText: '暂无数据' }"
      style="min-height: 350px"
    >
      <div slot="scopeName" slot-scope="row" class="td-box">
        {{ row.scopeName }}
      </div>
      <template slot="regionList" slot-scope="row">
        <div
          v-for="(item, index) in row.regionList"
          :key="index"
          class="region"
        >
          {{ item.name }}
        </div>
      </template>
    </a-table>
    <div slot="footer" class="modal-footer">
      <a-pagination
        v-if="form.total > 0"
        v-model="form.page"
        size="small"
        :pageSize="form.size"
        :total="form.total"
        @change="pageChange"
      />
    </div>
  </a-modal>
</template>

<script>
import api from "@/api/activityReport.js";

const scopeObject = {
  1: "所有用户可见",
  2: "部分用户可见",
  3: "仅自己可见",
};
export default {
  components: {},
  data() {
    return {
      visible: false,
      loading: false,

      scopeObject,

      columns: [
        {
          title: "用户名称",
          align: "left",
          scopedSlots: { customRender: "scopeName" },
        },
        {
          title: "区域",
          scopedSlots: { customRender: "regionList" },
        },
      ],
      form: {
        reportId: undefined,
        page: 1,
        size: 10,
        total: 0,
      },
      list: [],
    };
  },
  methods: {
    async openModal(id) {
      Object.assign(this.$data, this.$options.data());
      this.form.reportId = id;
      this.visible = true;
      await this.getReportScopeUserList();
    },
    /**
     * 获取报表详情
     * @param {number} id 报表id
     */
    async getReportScopeUserList() {
      try {
        this.loading = true;
        const { data: res } = await api.getReportScopeUserList(this.form);
        this.loading = false;
        if (res.code === 200) {
          const { list, total } = res.data;
          this.list = list;
          this.form.total = total;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        this.loading = false;
      }
    },
    /**
     * 页数改变
     * @param {number} page 修改的页数
     */
    pageChange(page) {
      this.form.page = page;
      this.getReportScopeUserList();
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
// 表格
::v-deep .ant-table-header,
::v-deep
  .ant-table-fixed-header
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body {
  background: #20232c;
}
::v-deep .ant-table-thead > tr > th {
  background: #242832;
  color: rgba(255, 255, 255, 0.88);
  font-size: 12px;
  border-bottom-color: rgba(255, 255, 255, 0.12);
  padding: 15px 6px;
  &:nth-child(2) {
    padding-left: 24px;
  }
}
::v-deep .ant-table-tbody > tr > td {
  color: rgba(255, 255, 255, 0.88);
  border-bottom-color: rgba(255, 255, 255, 0.12);
  padding: 0;
}
::v-deep
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: #20232c !important;
}
::v-deep .ant-table-placeholder {
  background: #1a1d26;
  border-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.88);
}

// 分页
::v-deep .ant-pagination-item a,
::v-deep .ant-pagination-disabled a,
::v-deep .ant-pagination-disabled:hover a,
::v-deep .ant-pagination-disabled:focus a,
::v-deep .ant-pagination-disabled .ant-pagination-item-link,
::v-deep .ant-pagination-disabled:hover .ant-pagination-item-link,
::v-deep .ant-pagination-disabled:focus .ant-pagination-item-link,
::v-deep .ant-pagination-prev a,
::v-deep .ant-pagination-next a,
::v-deep
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
::v-deep
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #9aa5be;
}
::v-deep .ant-pagination-prev:focus .ant-pagination-item-link,
::v-deep .ant-pagination-next:focus .ant-pagination-item-link,
::v-deep .ant-pagination-prev:hover .ant-pagination-item-link,
::v-deep .ant-pagination-next:hover .ant-pagination-item-link,
::v-deep
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
::v-deep
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon {
  color: #ffca5c;
}
::v-deep .ant-pagination-item-active {
  border: 1px solid transparent;
  background: none;
  a {
    color: #ffca5c;
  }
}

.td-box {
  padding: 15px 6px;
}

.region {
  height: 50px;
  line-height: 50px;
  border-left: 1px solid rgba(255, 255, 255, 0.08);
  padding-left: 24px;
  & + .region {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }
}
</style>
