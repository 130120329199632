<template>
  <div class="kpi-item">
    <span class="item-title">直播场次</span>
    <div style="margin: 32px 0 8px">
      <span class="font1">{{ 12 }}</span>
      <span class="font2">/{{ 12 }}</span>
    </div>
    <a-progress
      :percent="(12 / 15) * 100"
      :show-info="false"
      strokeColor="#FFCA5C"
    />
  </div>
</template>
<script>
export default {
  name: "KpiItem",
  components: {},
  props: {
    kpiData: {
      type: Object,
      defautl: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.kpi-item {
  width: 210px;
  height: 160px;
  background: #242832;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 31px 16px;
  .item-title {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.88);
    line-height: 22px;
  }
  .font1 {
    color: rgba(255, 255, 255, 0.88);
    font-weight: bold;
    font-size: 20px;
  }
  .font2 {
    color: rgba(255, 255, 255, 0.55);
    font-size: 14px;
  }
}
</style>