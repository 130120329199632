<template>
  <div>
    <platform-header
      ref="platformTab"
      title="经销商详情"
      :currentTab="currentTab"
      @tabChange="tabChange"
    />
    <div class="main-container">
      <!-- 顶部经销商详情信息 -->
      <div class="dealer-box">
        <!-- info -->
        <div class="dealer-box-info">
          <svg-icon icon-class="store-icon" class="big-svg" />
          <div class="dealer-box-info-right">
            <div>{{ detailData.dealer_name || "-" }}</div>
            <div>
              <span>{{ detailData.dealer_company_name || "-" }}</span>
              <svg-icon icon-class="store-icon" class="small-svg" />
              <span>{{ detailData.dealer_store_code }}</span>
              <svg-icon icon-class="area-icon" class="small-svg" />
              <span>{{ detailData.dealer_area_name || "-" }}</span>
              <svg-icon icon-class="position-icon" class="small-svg" />
              <span
                >{{ detailData.dealer_province_name
                }}{{ detailData.dealer_city_name }}</span
              >
            </div>
          </div>
        </div>
        <!-- 成就 -->
        <div class="dealer-box-achievement">
          <a-tooltip
            overlay-class-name="tooltip_name"
            v-for="(item, index) in detailData.liveadge"
            :key="'live' + index"
          >
            <template slot="title"> {{ item.badge_name }} </template>
            <svg-icon
              :icon-class="imgUrl('live', item.badge_type)"
              class="icon"
            />
          </a-tooltip>
          <a-tooltip
            overlay-class-name="tooltip_name"
            v-for="(item, index) in detailData.aweme_badge"
            :key="'video' + index"
          >
            <template slot="title"> {{ item.badge_name }} </template>
            <svg-icon
              :icon-class="imgUrl('video', item.badge_type)"
              class="icon"
            />
          </a-tooltip>
          <span class="fs1">近30天门店异动数量：</span>
          <span class="fs2">{{
            detailData.dealer_matrix_count | changeNum
          }}</span>
        </div>
        <!-- 等级 -->
        <div
          :class="['dealer-box-level', `dealer-box-level-${detailData.level}`]"
        >
          <span>门店评级:</span>
          <span>{{ detailData.level }}</span>
        </div>
      </div>
      <!-- 账号列表 -->
      <div class="account-box">
        <div class="account-box-header">
          <div class="header-item">账号昵称</div>
          <div class="header-item">粉丝总量</div>
          <div class="header-item">获赞总量</div>
          <div class="header-item">平台</div>
          <div class="header-item">账号等级</div>
          <div class="header-item">操作</div>
        </div>
        <div class="account-box-body">
          <div
            class="list-item"
            v-for="(item, index) in authorList"
            :key="index"
            @click="authorClick(item)"
          >
            <div class="list-item-col">
              <WebpImage
                :src="item.avatar"
                :width="'30px'"
                :height="'30px'"
                :radius="'50%'"
                class="avatar"
              />
              <svg-icon
                :icon-class="
                  item.verification_type == 2
                    ? 'account-type-blue'
                    : 'account-type-grey'
                "
                class="type-icon"
              />
              <span>{{ item.nickname }}</span>
            </div>
            <div class="list-item-col">
              {{ item.fans_count_total | changeNum }}
            </div>
            <div class="list-item-col">
              {{ item.like_count_total | changeNum }}
            </div>
            <div class="list-item-col">
              {{
                currentTab == "douyin"
                  ? "抖音"
                  : currentTab == "kuaishou"
                  ? "快手"
                  : currentTab == "dongchedi"
                  ? "懂车帝"
                  : "-"
              }}
            </div>
            <div class="list-item-col">
              <span
                :class="
                  item.level == 'S'
                    ? 'bc1'
                    : item.level == 'A'
                    ? 'bc2'
                    : item.level == 'B'
                    ? 'bc3'
                    : item.level == 'C'
                    ? 'bc4'
                    : 'bc'
                "
                >{{ item.level }}</span
              >
            </div>
            <div class="list-item-col">查看</div>
          </div>
          <Empty
            :title="'暂无账号'"
            v-show="!authorList || authorList.length == 0"
          />
        </div>
        <div class="shadow"></div>
      </div>
      <!-- live and video -->
      <div class="works-box">
        <div class="works-box-tools">
          <button-tabs
            :tabData="btnTabsList"
            @tabChange="handleBtnTabsChange"
            :defaultBtnStyle="defaultBtnStyle"
          />
        </div>
        <div class="works-box-main">
          <div>
            <WorksCard
              type="live"
              v-for="(item, index) in worksData.live_list || []"
              :key="index"
              :platform="currentTab"
              :cardData="item"
            />
            <Empty
              :title="'暂无直播'"
              v-show="!worksData.live_list || worksData.live_list.length == 0"
            />
          </div>
          <div>
            <WorksCard
              type="video"
              v-for="(item, index) in worksData.aweme_list || []"
              :key="index"
              :platform="currentTab"
              :cardData="item"
            />
            <Empty
              :title="'暂无视频'"
              v-show="!worksData.aweme_list || worksData.aweme_list.length == 0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/dealerDetail";
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import WebpImage from "@/components/WebpImage/WebpImage";
import ButtonTabs from "@/components/PlatformTab/NewButtonTab";
import WorksCard from "@/components/WorksCard/WorksCard";
import Empty from "@/components/Empty/Empty";

const btnTabsList = [
  {
    label: "最新发布",
    value: "1",
  },
  {
    label: "近30天最佳",
    value: "2",
  },
];
export default {
  name: "dealerDetail",
  components: { PlatformHeader, WebpImage, ButtonTabs, WorksCard, Empty },
  data() {
    return {
      msg: "经销商详情",
      currentTab: "",
      btnTabsList,
      listTab: "1",
      defaultBtnStyle: {
        height: "32px",
        "border-radius": "16px",
      },
      detailData: {},
      worksData: {},
      authorList: [],
    };
  },
  created() {
    this.currentTab = this.$route.query.platform || "";
    if (this.currentTab) {
      this.tabChange(this.currentTab);
    }
    // if (this.$route.query.id) {
    //   this.initData();
    // } else {
    //   console.log("没有获取到经销商信息");
    // }
  },
  computed: {},
  methods: {
    initData() {
      this.getDealerDetail();
      this.getDealerWorks();
      this.getDealerAuthors();
    },
    // 抖音快手切换
    tabChange(val) {
      console.log(val);
      this.currentTab = val;
      this.initData();
    },

    // 作品切换
    handleBtnTabsChange(val) {
      console.log("作品切换", val);
      if (this.listTab == val) {
        return false;
      }
      this.listTab = val;
      this.getDealerWorks();
    },

    getDealerDetail() {
      let body = {
        platform: this.currentTab,
        params: {
          dealer_id: this.$route.query.id,
        },
      };
      api.getDealerDetail(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.detailData = res.data.data || {};
        } else {
          console.error("获取经销商详情失败");
        }
      });
    },
    getDealerWorks() {
      let body = {
        platform: this.currentTab,
        params: {
          dealer_id: this.$route.query.id,
          type: this.listTab,
        },
      };
      api.getDealerWorks(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.worksData = res.data.data || {};
        } else {
          console.error("获取经销商作品表现失败");
        }
      });
    },
    getDealerAuthors() {
      let body = {
        platform: this.currentTab,
        params: {
          dealer_id: this.$route.query.id,
        },
      };
      api.getDealerAuthors(body).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data);
          this.authorList = res.data.data || [];
        } else {
          this.authorList = [];
          console.error("获取经销商账号列表失败");
        }
      });
    },
    // 徽章路径
    imgUrl(type, badge_type) {
      let url = "";
      if (type == "live") {
        switch (badge_type) {
          case 1:
            url = "live_no1";
            break;
          case 2:
            url = "live_no2";
            break;
          case 3:
            url = "live_no3";
            break;
          case 4:
            url = "live_able1";
            break;
          case 5:
            url = "live_able2";
            break;
          case 6:
            url = "live_able3";
            break;
          default:
            break;
        }
      } else {
        switch (badge_type) {
          case 1:
            url = "video_no1";
            break;
          case 2:
            url = "video_no2";
            break;
          case 3:
            url = "video_no3";
            break;
          case 4:
            url = "video_like1";
            break;
          default:
            break;
        }
      }
      return url;
    },
    authorClick(item) {
      if (item && item.author_id) {
        this.$router.push({
          path: "/dealer/production",
          query: {
            type: this.currentTab,
            author_id: item.author_id,
          },
        });
      } else {
        this.$message.warning("未获取到authorId!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.main-container {
  padding: 0 20px;
}
.dealer-box {
  width: 100%;
  height: 152px;
  background: #2b2f3b;
  border-radius: 16px;
  padding: 30px 0 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &-info {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .big-svg {
      font-size: 48px;
      color: #ffca5c;
      margin-right: 12px;
    }
    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      > div:first-child {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        line-height: 20px;
        margin-bottom: 6px;
      }
      > div:last-child {
        display: flex;
        align-items: center;
        > span {
          font-size: 12px;
          color: #9ca8ba;
        }
        > .small-svg {
          font-size: 16px;
          color: #9ca8ba;
          margin: 0 6px 0 20px;
        }
      }
    }
  }
  &-achievement {
    width: 100%;
    height: 30px;
    padding-left: 58px;
    display: flex;
    align-items: center;
    > .icon {
      font-size: 30px;
      margin-right: 20px;
    }
    .fs1 {
      font-size: 12px;
      color: #9ca8ba;
    }
    .fs2 {
      font-size: 14px;
      font-weight: bold;
      color: #ffca5c;
    }
  }
  &-level {
    width: 195px;
    height: 60px;
    border-radius: 30px 0 0 30px;
    border: 1px solid rgba(0, 0, 0, 0);
    @include border_color("border_color29");
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 36px;
    border-right: none;
    > span:first-child {
      font-size: 12px;
      color: #9ca8ba;
      line-height: 16px;
      margin: 0 10px 0 30px;
    }
    > span:last-child {
      font-size: 28px;
      font-weight: bold;
      @include font_color("font_color21");
    }
  }
  .dealer-box-level.dealer-box-level-S {
    @include border_color("border_color25");
    > span:last-child {
      @include font_color("font_color27");
    }
  }
  .dealer-box-level.dealer-box-level-A {
    @include border_color("border_color26");
    > span:last-child {
      @include font_color("font_color26");
    }
  }
  .dealer-box-level.dealer-box-level-B {
    @include border_color("border_color27");
    > span:last-child {
      @include font_color("font_color29");
    }
  }
  .dealer-box-level.dealer-box-level-C {
    @include border_color("border_color28");
    > span:last-child {
      @include font_color("font_color31");
    }
  }
}

.account-box {
  position: relative;

  &-header {
    width: 100%;
    height: 66px;
    padding: 30px 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    > .header-item {
      font-size: 14px;
      color: #6a7080;
      text-align: center;
      &:nth-child(1) {
        width: 26.8%;
        text-align: left;
        padding-left: 74px;
      }
      &:nth-child(2) {
        width: 16.5%;
      }
      &:nth-child(3) {
        width: 16.5%;
      }
      &:nth-child(4) {
        width: 14.7%;
      }
      &:nth-child(5) {
        width: 14.7%;
      }
      &:nth-child(6) {
        width: 10.7%;
      }
    }
  }
  &-body {
    width: 100%;
    max-height: 258px;
    overflow-y: auto;
    > .list-item {
      width: 100%;
      height: 70px;
      background: #2b2f3b;
      border-radius: 16px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      cursor: pointer;
      > .list-item-col {
        text-align: center;
        &:nth-child(1) {
          width: 26.8%;
          padding-left: 32px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .type-icon {
            font-size: 20px;
            margin: 0 4px 0 12px;
          }
          > span {
            font-size: 14px;
            color: #ffffff;
          }
        }
        &:nth-child(2) {
          width: 16.5%;
          font-size: 14px;
          font-weight: bold;
          color: #ffca5c;
        }
        &:nth-child(3) {
          width: 16.5%;
          font-size: 14px;
          font-weight: bold;
          color: #9ca8ba;
        }
        &:nth-child(4) {
          width: 14.7%;
          font-size: 14px;
          font-weight: bold;
          color: #9ca8ba;
        }
        &:nth-child(5) {
          width: 14.7%;
          > span {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            line-height: 30px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            display: inline-block;
          }
          .bc1 {
            @include background_color("background_color52");
            @include font_color("font_color27");
          }
          .bc2 {
            @include background_color("background_color53");
            @include font_color("font_color26");
          }
          .bc3 {
            @include background_color("background_color54");
            @include font_color("font_color29");
          }
          .bc4 {
            @include background_color("background_color60");
            @include font_color("font_color31");
          }
          .bc {
            @include background_color("background_color55");
            @include font_color("font_color21");
          }
        }
        &:nth-child(6) {
          width: 10.7%;
          font-size: 14px;
          color: #9ca8ba;
        }
      }
    }

    > .list-item:hover {
      background: #242832;
      > .list-item-col {
        &:nth-child(6) {
          font-weight: bold;
          color: #ffca5c;
        }
      }
    }
  }

  .shadow {
    width: 100%;
    height: 20px;
    background: linear-gradient(180deg, rgba(26, 29, 38, 0) 0%, #1a1d26 100%);
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.works-box {
  margin: 20px 0;
  &-tools {
    height: 32px;
    margin-bottom: 20px;
  }
  &-main {
    width: 100%;
    height: 672px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    > div {
      width: calc(50% - 5px);
      height: 100%;
      display: flex;
      flex-direction: column;
      > div {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
